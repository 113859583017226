import { Select } from 'antd';
import { promotionClient } from 'pkg/request/clients';
import React from 'react';
import { useState } from 'react';

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

export const SearchInput: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  handleChange: (user: string) => void;
}> = (props) => {
  const { Option } = Select;

  const [data, setData] = useState<any[]>([]);
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetch(newValue, setData);
    } else {
      setData([]);
    }
  };

  const handleSelect = (newValue: string) => {
    props.handleChange(newValue);
  };

  const options = data.map((d) => (
    <Option key={d} value={d}>
      {d}
    </Option>
  ));

  const fetch = (
    value: string,
    callback: (data: { value: string; text: string }[]) => void
  ) => {
    setLoading(true);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    const fake = () => {
      promotionClient
        .post('/api/v1/manager/user/list_like', { user_name: value })
        .then((res: any) => {
          callback(res.data.list);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    timeout = setTimeout(fake, 300);
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleSelect}
      notFoundContent={null}
      loading={loading}
    >
      {options}
    </Select>
  );
};
