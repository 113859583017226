import { Card, Col, Form, Input, notification, Row, Select } from 'antd';
import { t } from 'i18n';
import { debounce } from 'lodash';
import { BackButton, SaveButton } from 'modules/base/Buttons';
import { SALE_MAN } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import InputSearchUserName from './components/InputSearchUserName';
import { bankData } from './dataBank';
const tabList = [
  {
    key: 'info',
    tab: 'Create a new bank',
  },
];

const tailLayout = {
  wrapperCol: { span: 17 },
};

export const fake = (input: string, callback: any) => {
  if (input) {
    const data = bankData.filter((bank: any) => {
      return (
        bank.vn_name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        bank.shortName.toLowerCase().indexOf(input.toLowerCase()) > -1
      );
    });
    callback(data);
  } else {
    callback(bankData);
  }
};

const CreateBank = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { Option } = Select;

  const [url, setUrl] = useState('');

  useEffect(() => {
    const role = getCookie('author_user');
    if (role) {
      if (role === SALE_MAN) {
        setUrl('/api/v1/manager/bank_info/add_mine');
      } else {
        setUrl('/api/v1/manager/bank_info/add');
      }
    }
  }, [getCookie('author_user')]);

  const onSubmit = (data: any) => {
    if (roleName === SALE_MAN) {
      data = { ...data, user_name: getCookie('current_username') };
    }
    setLoading(true);
    promotionClient
      .post(url, {
        ...data,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Create a new bank successfully',
          });
          history.push(PATH.BANK.LIST);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const onFinishFailed = (val: any) => {};
  const [value, setValue] = useState<string>();
  const [data, setData] = useState<any[]>(bankData);
  const [valueUserName, setValueUserName] = useState<string>('');

  const options = data.map((d) => (
    <Option key={d.shortName} value={d.vn_name}>
      {`${d.vn_name} (${d.shortName})`}
    </Option>
  ));

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fake(newValue, setData);
    } else {
      setData(bankData);
    }
  };
  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    form.setFieldsValue({
      user_name: valueUserName,
    });
  }, [valueUserName]);

  const [roleName, setRoleName] = useState('');
  useEffect(() => {
    const role = getCookie('author_user');

    if (role) {
      setRoleName(role);
    }
  }, [getCookie('author_user')]);

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Row>
        <Col span={8}>
          <Form
            layout="vertical"
            name="updateUser"
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            {roleName !== SALE_MAN && (
              <Form.Item
                label={t('bank.list.userName')}
                name="user_name"
                rules={[{ required: true, message: 'Please enter user name!' }]}
              >
                <InputSearchUserName
                  valueUserName={valueUserName}
                  setValueUserName={setValueUserName}
                />
              </Form.Item>
            )}
            <Form.Item
              label={t('bank.list.bankName')}
              name="bank_name"
              rules={[{ required: true, message: 'Please enter bank name!' }]}
            >
              <Select
                showSearch
                value={value}
                // placeholder={props.placeholder}
                // style={props.style}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
              >
                {options}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('bank.list.bankNumber')}
              name="bank_number"
              rules={[
                { required: true, message: 'Please enter file bank number!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('bank.list.bankHolder')}
              name="bank_holder"
              rules={[
                { required: true, message: 'Please enter file bank holder!' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item className="staff-item" {...tailLayout}>
              <BackButton />
              <SaveButton htmlType="submit" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateBank;
