import { useContext, useEffect, useState } from 'react';
import { StoreContext } from 'contexts';
import * as userServices from 'pkg/user/repo';
import { filterHasPermissions } from './helpers';
import { IRoute } from 'pkg/route/types';
import { IUser } from 'modules/user/types';

export const useUserInfo = () => {
  const [currentUser, setCurrentUser] = useState<IUser>();

  const getFullUserInfo = async () => {
    const fullUserInfo = await userServices.getFullUserInfo();
    setCurrentUser(fullUserInfo);
  };

  useEffect(() => {
    // getFullUserInfo();
  }, []);
  return { currentUser };
};

export const useAuthorizationData = (items: Array<IRoute>) => {
  const { currentUser } = useContext(StoreContext);
  // Get navigation which match permissions to build menu
  const filteredNavigation = filterHasPermissions(items, currentUser);

  // Only get routes for current SC version
  const filteredRoutes = filteredNavigation.filter(
    (item) => !item.children && item.component
  );

  return {
    filteredNavigation,
    filteredRoutes,
  };
};
