import { Card, Col, Divider, notification, Radio, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import Blacklist from './Blacklist/Blacklist';
import ChildrenTab from './components/ChildrenTab';
import Setting from './components/Setting';
import Software from './components/Software';
import './Rule.scss';
import BlockappManager from './components/BlockappManager';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import LicenseStatus from 'modules/base/LicenseStatus';
import { SearchLikeInput } from './components/SearchAndSelectLike';

const tabList = [
  {
    key: 'info',
    tab: 'User detail',
  },
];

const Children = () => {
  const [user, setUser] = useState<any>('');
  const [tab, setTab] = useState<string>('1');
  const handleChange = (user: any) => {
    setUser(user);
    getUser(user.parent_id);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [userDetail, setUserDetail] = useState<any>(null);

  const getUser = (id: any) => {
    promotionClient
      .post(`/api/v1/manager/user/info`, { user_name: id })
      .then((res: any) => {
        if (res.data) {
          setUserDetail(res.data.user);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let parent_id = new URLSearchParams(window.location.search).get(
      'parent_id'
    );
    if (parent_id) {
      setUser({ parent_id });
      getUser(parent_id);
    }
  }, []);

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <div>
        <Row gutter={[16, 16]}>
          <Col span={6} className="title-detail">
            {t('users.detail.form.username')}
          </Col>
          <Col span={18}>{userDetail?.user_name}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.email')}
          </Col>
          <Col span={18}>{userDetail?.email}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.phone')}
          </Col>
          <Col span={18}>{userDetail?.phone}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.fullName')}
          </Col>
          <Col span={18}>{userDetail?.full_name}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.country')}
          </Col>
          <Col span={18}>{userDetail?.country}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.timezone')}
          </Col>
          <Col span={18}>{userDetail?.time_zone}</Col>
        </Row>
      </div>
    ),
  };
  return (
    <div>
      {userDetail && (
        <div style={{ padding: '20px 0px' }}>
          Parent: {userDetail.user_name} - {userDetail.full_name}
        </div>
      )}
      <Tabs
        defaultActiveKey={tab}
        activeKey={tab}
        onChange={(key: string) => setTab(key)}
      >
        <Tabs.TabPane tab="Parent" key="1">
          <SearchLikeInput
            placeholder="search parent"
            style={{ width: 200 }}
            handleChange={handleChange}
          />
          {userDetail && (
            <Row style={{ margin: '50px 0px' }}>
              <Col span={24}>
                <Card
                  loading={loading}
                  style={{ width: '100%' }}
                  tabList={tabList}
                  activeTabKey={'info'}
                >
                  {contentList['info']}
                </Card>

                <LicenseStatus
                  loading={loading}
                  license={{
                    title: `${t('license.title')}: ${userDetail.license}`,
                    begin: userDetail.subscription_begin,
                    end: userDetail.subscription_end,
                  }}
                />
              </Col>
            </Row>
          )}
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="User management" key="8" disabled={!user}>
        <UserManagement user={user} tab={tab} />
      </Tabs.TabPane> */}
        <Tabs.TabPane tab="Children" key="7" disabled={!user}>
          <ChildrenTab user={user.parent_id} tab={tab} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Black list" key="2" disabled={!user}>
          {tab === '2' && (
            <Blacklist
              tab={tab}
              apiGet="/api/v1/manager/web_rule/blacklist/list"
              apiCreate="/api/v1/manager/web_rule/blacklist/create"
              apiEditName="/api/v1/manager/web_rule/set_name"
              apiDeleteRule="/api/v1/manager/web_rule/delete"
              apiEditDetailRule="/api/v1/manager/web_rule/edit"
              apiDeleteDetailRule="/api/v1/manager/web_rule/edit"
              userId={user.parent_id}
              type="blacklist"
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="White list" key="3" disabled={!user}>
          {tab === '3' && (
            <Blacklist
              tab={tab}
              apiGet="/api/v1/manager/web_rule/whitelist/list"
              apiCreate="/api/v1/manager/web_rule/whitelist/create"
              apiEditName="/api/v1/manager/web_rule/set_name"
              apiDeleteRule="/api/v1/manager/web_rule/delete"
              apiEditDetailRule="/api/v1/manager/web_rule/edit"
              apiDeleteDetailRule="/api/v1/manager/web_rule/edit"
              userId={user.parent_id}
              type="whitelist"
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Block app" key="4" disabled={!user}>
          {tab === '4' && (
            <Blacklist
              tab={tab}
              apiGet="/api/v1/manager/app_rule/blocklist/list_admin"
              apiCreate="/api/v1/manager/app_rule/blocklist/create_admin"
              apiEditName="/api/v1/manager/app_rule/set_name"
              apiDeleteRule="/api/v1/manager/app_rule/delete"
              apiEditDetailRule="/api/v1/manager/app_rule/blocklist/edit_admin"
              apiDeleteDetailRule="/api/v1/manager/app_rule/blocklist/edit_admin"
              userId={user.parent_id}
              type="blocklist"
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Timer" key="8" disabled={!user}>
          <BlockappManager user={user.parent_id} tab={tab} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Settings" key="5" disabled={!user}>
          <Setting user={user.parent_id} tab={tab} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Softwares" key="6" disabled={!user}>
          <Software user={user.parent_id} tab={tab} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Children;
