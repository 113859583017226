export const CAMPAIGNS_PATH = {
  LIST: '/campaigns',
  CREATE: '/campaigns/:type/new',
  EDIT: '/campaigns/:id/edit',
  DETAIL: '/campaigns/:id',
};

export const PATH = {
  HOME: '/',
  LOGIN: '/login',
  SSO: '/sso',
  AGENTPAGE: '/agentpage/:agent_id/:parent_id/tabs/:tab',
  REGISTER: '/register',
  VERIFYACCOUNT: '/account/activate/:id',
  CAMPAIGNS: CAMPAIGNS_PATH,
  CAMPAIGN: '/campaigns',
  CAMPAIGN_DETAIL: '/campaigns/:id',
  CAMPAIGN_EDIT: '/campaigns/:id/edit',
  WEBSITE: '/website',
  DASHBOARD: '/Dashboard',
  APPLICATION: '/application',
  POLICY: '/policy',
  USER: {
    LIST: '/users',
    PAGE: '/usermanager',
    DETAIL: '/usermanager/:id',
    EDIT: '/usermanager/:id/edit',
  },
  RPA_PAGE: '/rpamanage',
  PROMOTION: {
    LIST: '/promotion',
    DETAIL: '/promotion/:id/:code',
    DETAIL_SALES_MAN: '/promotion/:id/:code/sale',
    CREATE: '/promotion/create',
    EDIT: '/promotion/:id/:code/edit',
  },
  BROWSER: {
    LIST: '/browser',
    CREATE: '/create-browser',
    DETAIL: '/browser/:id',
    EDIT: '/browser/:id/edit',
  },
  SALE: {
    LIST: '/sale',
  },
  BANK: {
    LIST: '/bank',
    DETAIL: '/bank/:id',
    CREATE: '/bank-create',
    EDIT: '/bank/:id/edit',
  },
  RULE: {
    LIST: '/rule',
    DETAIL: '/rule/:id',
    CREATE: '/rule-create',
    EDIT: '/rule/:id/edit',
  },
  CHILDREN: {
    LIST: '/children',
    DETAIL: '/children/:id',
    CREATE: '/children-create',
    EDIT: '/children/:id/edit',
  },
  HISTORY: {
    LIST: '/history',
  },
};
