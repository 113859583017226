import { DeleteTwoTone, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, notification, Popconfirm, Row, Table, Tabs } from 'antd';
import { t } from 'i18n';
import { AddButton } from 'modules/base/Buttons';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import './Browser.scss';

const BrowserList = () => {
  const history = useHistory();
  const [browser, setBrowser] = useState([]);

  const [loading, setLoading] = useState<boolean>(false);

  const { TabPane } = Tabs;

  const [showConfirmDeleteBrowser, setShowConfirmDeleteBrowser] = useState({
    show: false,
    id: null,
  });
  const [showConfirmApplyBrowser, setShowConfirmApplyBrowser] =
    useState<boolean>(false);

  useEffect(() => {
    getBrowser();
  }, []);

  const handDeleteBrowser = (browser: any) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/black_browser/delete`, { id: browser.id })
      .then((res: any) => {
        if (res.data) {
          notification.success({
            message: t('Success'),
            description: 'Delete block browser successfully',
          });
          history.push(PATH.BROWSER.LIST);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: t('browser.list.no'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: t('browser.list.browserName'),
      dataIndex: 'browser_name',
      key: 'browser_name',
    },
    {
      title: t('browser.list.fileName'),
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: t('browser.list.originalName'),
      dataIndex: 'original_name',
      key: 'original_name',
    },

    {
      title: t('browser.list.fileDescription'),
      dataIndex: 'file_description',
      key: 'file_description',
    },

    {
      title: t('browser.list.action'),
      dataIndex: '',
      key: 'delete',
      render: (item: any, value: any) => (
        <div className="ant-btn-group" style={{ marginLeft: '5px' }}>
          <Button
            icon={<SearchOutlined />}
            onClick={() => history.push(`${PATH.BROWSER.LIST}/${value.id}`)}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() =>
              history.push(`${PATH.BROWSER.LIST}/${value.id}/edit`)
            }
          />
          <Popconfirm
            placement="topRight"
            title={'Are you sure delete this browser?'}
            onConfirm={() => handDeleteBrowser(value)}
            onCancel={() => {
              setShowConfirmDeleteBrowser({ show: false, id: null });
            }}
            okText="Yes"
            cancelText="No"
            visible={
              showConfirmDeleteBrowser.show &&
              showConfirmDeleteBrowser.id === value.id
            }
          >
            <Button
              icon={<DeleteTwoTone twoToneColor="red" />}
              onClick={() =>
                setShowConfirmDeleteBrowser({ show: true, id: value.id })
              }
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handApplyBrowser = () => {
    promotionClient
      .get(`/api/v1/manager/black_browser/apply`)
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Apply rule successfully',
          });
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {
        setShowConfirmApplyBrowser(false);
      });
  };

  const operations = (
    <>
      <AddButton onClick={() => history.push(PATH.BROWSER.CREATE)}>
        Create
      </AddButton>
      <Popconfirm
        placement="topRight"
        title={'Are you sure apply this rule?'}
        onConfirm={() => handApplyBrowser()}
        onCancel={() => {
          setShowConfirmApplyBrowser(false);
        }}
        okText="Yes"
        cancelText="No"
        visible={showConfirmApplyBrowser}
      >
        <Button
          type="primary"
          danger
          onClick={() => setShowConfirmApplyBrowser(true)}
        >
          Apply browser rule
        </Button>
      </Popconfirm>
    </>
  );
  const getBrowser = () => {
    setLoading(true);
    promotionClient
      .get(`/api/v1/manager/black_browser/list`)
      .then((res: any) => {
        if (res.data.total) {
          setBrowser(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
      <TabPane tab={t('menu.blockBrowser')} key="1">
        <Row gutter={15}>
          <Col className="gutter-row" lg={24} md={24} xs={24}>
            <div className="table-staff">
              <Table
                rowKey={(record) => record.id}
                bordered
                pagination={false}
                scroll={{ x: 600 }}
                dataSource={browser}
                columns={columns}
                loading={loading}
              />
            </div>
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  );
};

export default BrowserList;
