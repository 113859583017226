import { notification, Select } from 'antd';
import { t } from 'i18n';
import { debounce } from 'lodash';
import { SALE_MAN } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';

type Props = {
  valueUserName: string;
  setValueUserName: (valueUserName: string) => void;
};

const InputSearchUserName = ({ valueUserName, setValueUserName }: Props) => {
  const { Option } = Select;
  const [dataUserName, setDataUerName] = useState<any[]>([]);

  const handleChangeUserName = (newValue: string) => {
    setValueUserName(newValue);
  };
  const optionsUserName = dataUserName.map((d) => (
    <Option key={d} value={d}>
      {d}
    </Option>
  ));

  const handleSearchUserName = (newValue: string) => {
    promotionClient
      .post(`/api/v1/manager/user/list_like`, {
        user_name: newValue,
      })
      .then((res: any) => {
        if (res.data.success) {
          setDataUerName(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err) => {});
  };
  return (
    <Select
      showSearch
      value={valueUserName}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={debounce(handleSearchUserName, 300)}
      onChange={handleChangeUserName}
      notFoundContent={null}
    >
      {optionsUserName}
    </Select>
  );
};

export default InputSearchUserName;
