import { Col, Row, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { handleToDay } from '../Tabs/action';
import './AgentPage.scss';
// import ButtonSelectDay from './components/ButtonSelectDay';
import ChartContent from 'modules/Agent/ChartContent';
import { promotionClient } from 'pkg/request/clients';
import ButtonSelectDay from '../Tabs/ButtonSelectDay';
import { t } from 'i18n';
type Props = {
  getAdminId: () => void;
  getTimeZone: () => void;
  agentId: string;
};
const ChartAgent = ({ getAdminId, getTimeZone, agentId }: Props) => {
  const [dataSourceChart, setDataSourceChart] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApiChart(0);
  }, []);

  const getApiChart = (number: number) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/app_usage/chart`, {
        admin_id: getAdminId(),
        agent_id: agentId,
        sort: 'desc',
        date: handleToDay(number),
        top: 20,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        setDataSourceChart(res.data.apps);
        setLoading(false);
      })
      .catch((err: any) => {
        if (err?.code === 'ERR_CANCELED') {
          notification.error({
            message: t('Error'),
            description: t('serveError.error'),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatChart = () => {
    const result = dataSourceChart.map((item: any) => {
      item.sales = +item.usage_time;
      item.type = item.app_name;
      return item;
    });
    return result;
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <ButtonSelectDay actionFunction={getApiChart} />
        </Col>
      </Row>

      {!!dataSourceChart.length && !loading && (
        <ChartContent data={formatChart()} />
      )}
    </Spin>
  );
};

export default ChartAgent;
