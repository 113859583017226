import {
  roleMenuUser,
  roleMenuPromotion,
  roleMenuSale,
  roleMenuBlockBrowser,
  allRole,
} from './../pkg/cookie/constants';
import {
  BankOutlined,
  DollarCircleOutlined,
  HistoryOutlined,
  IeOutlined,
  LockOutlined,
  PercentageOutlined,
  ThunderboltOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { t } from 'i18n';
import Application from 'modules/Application/Application';
import BrowserList from 'modules/Dashboard/Browser/Browser';
import CreateBrowser from 'modules/Dashboard/Browser/CreateBrowser';
import DetailBrowser from 'modules/Dashboard/Browser/DetailBrowser';
import EditBrowser from 'modules/Dashboard/Browser/EditBrowser';
import Dashboard from 'modules/Dashboard/Dashboard';
import CreatePromotion from 'modules/Dashboard/Promotion/CreatePromotion';
import DetailPromotion from 'modules/Dashboard/Promotion/DetailPromotion';
import EditPromotion from 'modules/Dashboard/Promotion/EditPromotion';
import PromotionList from 'modules/Dashboard/Promotion/Promotions';
import Sales from 'modules/Dashboard/Sale/Sales';
// import DetailPromotionSalesMan from 'modules/Dashboard/Sale/DetailPromotionSelesMan';
import DetailUser from 'modules/Dashboard/User/DetailUser';
import EditUser from 'modules/Dashboard/User/EditUser';
import User from 'modules/Dashboard/User/User';
import { MANAGER, SUPPORTER } from 'pkg/cookie/constants';
import { lazy } from 'react';
import Website from '../modules/website/Website';
import { PATH } from './constants';
import BankList from 'modules/Dashboard/Bank/Bank';
import DetailBank from 'modules/Dashboard/Bank/DetailBank';
import CreateBank from 'modules/Dashboard/Bank/CreateBank';
import EditBank from 'modules/Dashboard/Bank/EditBank';
import RuleList from 'modules/Dashboard/AppRules/Rule';
import DetailRule from 'modules/Dashboard/AppRules/DetailRule';
import EditRule from 'modules/Dashboard/AppRules/EditRule';
import Children from 'modules/Dashboard/Children/Children';
import DetailPromotionSalesMan from 'modules/Dashboard/Sale/DetailPromotionSeleMan';
import Sso from 'modules/login/Sso';
import History from 'modules/Dashboard/History';
import AgentPage from 'modules/Dashboard/History/components/agentPage/AgentPage';
// import DetailUser from 'modules/Dashboard/User/Detail';

// Home page
const Login = lazy(() => import('modules/login/Login'));
// const AgentPage = lazy(() => import('modules/Dashboard/agentPage/AgentPage'));
const Register = lazy(() => import('modules/register/Register'));
const UserList = lazy(() => import('modules/user/User'));
const VerifyAccount = lazy(() => import('modules/verifyAccount/VerifyAccount'));

const routes = [
  {
    exact: true,
    path: PATH.LOGIN,
    name: t('Login'),
    component: Login,
  },
  {
    exact: true,
    path: PATH.REGISTER,
    name: t('Register'),
    component: Register,
  },
  {
    exact: true,
    path: PATH.SSO,
    name: t('sos'),
    component: Sso,
  },
  // {
  //   exact: true,
  //   path: PATH.AGENTPAGE,
  //   name: t('Agentpage'),
  //   component: AgentPage,
  // },
  // {
  //   exact: true,
  //   path: PATH.VERIFYACCOUNT,
  //   name: t('VerifyAccount'),
  //   component: VerifyAccount,
  // },
  {
    exact: true,
    path: PATH.DASHBOARD,
    name: t('campaign.Dashboard'),
    component: Dashboard,
    // icon: LaptopOutlined,
  },
  {
    exact: true,
    path: PATH.USER.PAGE,
    name: t('menu.user'),
    component: User,
    icon: UserOutlined,
    role: roleMenuUser,
  },
  // {
  //   exact: true,
  //   path: PATH.WEBSITE,
  //   name: t('campaign.website'),
  //   component: Website,
  // },
  // {
  //   exact: true,
  //   path: PATH.APPLICATION,
  //   name: t('campaign.application'),
  //   component: Application,
  // },
  // {
  //   exact: true,
  //   path: PATH.USER.LIST,
  //   name: t('user.management'),
  //   component: UserList,
  //   icon: UserOutlined,
  // },
  {
    exact: true,
    path: PATH.USER.EDIT,
    name: t('user.edit'),
    component: EditUser,
    role: roleMenuUser,
  },
  {
    exact: true,
    path: PATH.USER.DETAIL,
    name: t('user.detail'),
    component: DetailUser,
    role: roleMenuUser,
  },
  {
    exact: true,
    path: PATH.PROMOTION.LIST,
    name: t('menu.promotion'),
    component: PromotionList,
    icon: PercentageOutlined,
    role: roleMenuPromotion,
  },
  {
    exact: true,
    path: PATH.PROMOTION.DETAIL,
    name: t('promotion.detail.title'),
    component: DetailPromotion,
    role: roleMenuPromotion,
  },
  {
    exact: true,
    path: PATH.PROMOTION.DETAIL_SALES_MAN,
    name: t('promotion.detail.title'),
    component: DetailPromotionSalesMan,
    role: roleMenuSale,
  },
  {
    exact: true,
    path: PATH.PROMOTION.CREATE,
    name: t('promotion.create.title'),
    component: CreatePromotion,
    role: roleMenuPromotion,
  },
  {
    exact: true,
    path: PATH.PROMOTION.EDIT,
    name: t('promotion.edit.title'),
    component: EditPromotion,
    role: roleMenuPromotion,
  },
  {
    exact: true,
    path: PATH.SALE.LIST,
    name: t('sale.menu'),
    component: Sales,
    icon: DollarCircleOutlined,
    role: roleMenuSale,
  },
  {
    exact: true,
    path: PATH.BROWSER.LIST,
    name: t('menu.blockBrowser'),
    component: BrowserList,
    icon: IeOutlined,
    role: roleMenuBlockBrowser,
  },
  {
    exact: true,
    path: PATH.BROWSER.DETAIL,
    name: t('browser.detail.title'),
    component: DetailBrowser,
    role: roleMenuBlockBrowser,
  },
  {
    exact: true,
    path: PATH.BROWSER.CREATE,
    name: t('browser.create.title'),
    component: CreateBrowser,
    role: roleMenuBlockBrowser,
  },
  {
    exact: true,
    path: PATH.BROWSER.EDIT,
    name: t('browser.edit.title'),
    component: EditBrowser,
    role: roleMenuBlockBrowser,
  },

  {
    exact: true,
    path: PATH.BANK.LIST,
    name: t('menu.bank'),
    component: BankList,
    icon: BankOutlined,
    role: allRole,
  },
  {
    exact: true,
    path: PATH.BANK.DETAIL,
    name: t('bank.detail.title'),
    component: DetailBank,
    role: allRole,
  },
  {
    exact: true,
    path: PATH.BANK.CREATE,
    name: t('bank.create.title'),
    component: CreateBank,
    role: allRole,
  },
  {
    exact: true,
    path: PATH.BANK.EDIT,
    name: t('bank.edit.title'),
    component: EditBank,
    role: allRole,
  },

  // app rule
  {
    exact: true,
    path: PATH.RULE.LIST,
    name: t('menu.rule'),
    component: RuleList,
    icon: ThunderboltOutlined,
    role: roleMenuBlockBrowser,
  },
  {
    exact: true,
    path: PATH.RULE.DETAIL,
    name: t('rule.detail.title'),
    component: DetailRule,
    role: roleMenuBlockBrowser,
  },

  {
    exact: true,
    path: PATH.RULE.EDIT,
    name: t('rule.edit.title'),
    component: EditRule,
    role: roleMenuBlockBrowser,
  },

  // children
  {
    exact: true,
    path: PATH.CHILDREN.LIST,
    name: t('menu.children'),
    component: Children,
    icon: LockOutlined,
    role: roleMenuPromotion,
  },
  {
    exact: true,
    path: PATH.HISTORY.LIST,
    name: t('menu.history'),
    component: History,
    icon: HistoryOutlined,
    role: roleMenuPromotion,
  },
  {
    exact: true,
    path: PATH.AGENTPAGE,
    name: t('Agentpage'),
    component: AgentPage,
    role: roleMenuPromotion,
  },
];

export default routes;
