import { Card, Col, Divider, notification, Row } from 'antd';
import { t } from 'i18n';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import LicenseStatus from 'modules/base/LicenseStatus';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { PATH } from 'routes/constants';
import { formatter } from '../Promotion/Promotions';
const tabList = [
  {
    key: 'info',
    tab: t('promotion.detail.title'),
  },
];

type Params = {
  id: string;
  code: string;
};

const DetailPromotionSalesMan = () => {
  const router = useRouter<Params>();
  const { id, code } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [promotion, setPromotion] = useState<any>(null);
  const [countPromotion, setCountPromotion] = useState<any>(null);

  const getDetailPromotion = (code: string) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/promotion/get_mine`, { code })
      .then((res: any) => {
        if (res.data) {
          setPromotion(res.data);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getCountPromotion = (code: string) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/promotion/count`, { code })
      .then((res: any) => {
        if (res.data) {
          setCountPromotion(res.data);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (code) {
      getDetailPromotion(code);
      getCountPromotion(code);
    }
  }, [id, code]);

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Row gutter={[16, 16]}>
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.package')}
        </Col>
        <Col span={18}>{promotion?.package}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.type')}
        </Col>
        <Col span={18}>{promotion?.type}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.create.form.min')}
        </Col>
        <Col span={18}>{promotion?.min}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.percent')}
        </Col>
        <Col span={18}>{`${promotion?.percent} %`}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.money')}
        </Col>
        <Col span={18}>{formatter(promotion?.money)}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.unit')}
        </Col>
        <Col span={18}>{promotion?.unit}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.month')}
        </Col>
        <Col span={18}>{promotion?.month}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.reward')}
        </Col>
        <Col span={18}>{formatter(promotion?.reward)}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.rewardType')}
        </Col>
        <Col span={18}>{formatter(promotion?.reward_type)}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.expire')}
        </Col>
        <Col span={18}>{promotion?.expire}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.status')}
        </Col>
        <Col span={18}>{promotion?.status}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.email')}
        </Col>
        <Col span={18}>{promotion?.email}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('promotion.detail.list.note')}
        </Col>
        <Col span={18}>{promotion?.note}</Col>
      </Row>
    ),
  };

  const getValueReward = (reward: string, count: string, unit = '') => {
    return `${formatter(reward)} x ${count} = ${formatter(
      +count * +reward
    )} ${unit}`;
  };
  const dataInfo = () => {
    const data = [
      { label: 'promotion.info.code', value: promotion.code },
      { label: 'Count', value: countPromotion.count },
    ];

    const sale =
      promotion.reward_type === 'percent'
        ? {
            label: 'Sales',
            value: formatter(countPromotion.sum) + ' ' + promotion.unit,
          }
        : null;
    if (sale) data.push(sale);
    const reward =
      promotion.reward_type === 'percent'
        ? { label: 'Reward', value: `${promotion.reward}%` }
        : {
            label: 'Reward',
            value: `${formatter(promotion.reward)} ${promotion.unit}`,
          };
    data.push(reward);
    const commission =
      promotion.reward_type === 'money'
        ? {
            label: 'Commission',
            value: getValueReward(
              promotion.reward,
              countPromotion.count,
              promotion.unit
            ),
          }
        : {
            label: 'Commission',
            value:
              formatter((promotion.reward * countPromotion.sum) / 100) +
              ' ' +
              (promotion.unit || ''),
          };
    data.push(commission);
    return data;
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {promotion && countPromotion && (
            <>
              <InfoWithAvatar
                id={id}
                data={dataInfo()}
                loading={loading}
                icon={countPromotion.count}
                path={`${PATH.PROMOTION.LIST}/${id}/${code}/edit`}
              />
              <LicenseStatus
                loading={loading}
                license={{
                  title: `${t('promotion.status.title')}: ${promotion.status}`,
                  begin: promotion.created_at,
                  end: promotion.expire,
                }}
              />
            </>
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailPromotionSalesMan;
