export const KEY_ACCESS_TOKEN = 'access_token';
export const KEY_REFRESH_TOKEN = 'refresh_token';
export const KEY_TIMEZONE = 'timezone';
export const AUTHOR_USER = 'author_user';
export const KEY_CURRENT_USER_ID = 'current_user_id';
export const KEY_CURRENT_USERNAME = 'current_username';
export const COOKIE_DOMAIN = ';domain=.teko.vn';
export const CURRENT_SELLER = 'sc_current_seller';

export const SUPPORTER = 'supporter';
export const MANAGER = 'manager';
export const SALE_MAN = 'salesman';

export const roleMenuUser = ['manager', 'supporter'];
export const roleMenuPromotion = ['manager', 'supporter'];
export const roleMenuSale = ['salesman'];
export const roleMenuBlockBrowser = ['manager'];
export const allRole = ['supporter', 'manager', 'salesman'];
