import { Card, Col, Form, Input, notification, Row, Select } from 'antd';
import { t } from 'i18n';
import { BackButton, SaveButton } from 'modules/base/Buttons';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { fake } from './CreateBank';
import { bankData } from './dataBank';
const tabList = [
  {
    key: 'info',
    tab: t('bank.edit.title'),
  },
];
const tailLayout = {
  wrapperCol: { span: 17 },
};
const { Option } = Select;

type Params = {
  id: string;
};

const EditBank = () => {
  const router = useRouter<Params>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [url, setUrl] = useState({ get: '', post: '' });

  const getDetailBrowser = (id: string) => {
    setLoading(true);
    promotionClient
      .post(url.get, { id })
      .then((res: any) => {
        if (res.data) {
          setData(res.data.item);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     user_name: valueUserName,
  //   });
  // }, [valueUserName]);

  useEffect(() => {
    if (id && url.get) {
      getDetailBrowser(id);
    }
  }, [id, url]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const onFinishFailed = (val: any) => {};

  useEffect(() => {
    const role = getCookie('author_user');
    if (role) {
      if (role === 'salesman') {
        setUrl({
          post: '/api/v1/manager/bank_info/edit_mine',
          get: '/api/v1/manager/bank_info/get_mine',
        });
      } else {
        setUrl({
          get: '/api/v1/manager/bank_info/get',
          post: '/api/v1/manager/bank_info/edit',
        });
      }
    }
  }, [getCookie('author_user')]);
  const onSubmit = (val: any) => {
    const data = {
      ...val,
      id,
    };
    setLoading(true);
    promotionClient
      .post(url.post, data)
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Update browser success',
          });
          history.push(`${PATH.BANK.LIST}/${id}`);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [value, setValue] = useState<string>();
  const [dataBankOption, setDataBankOption] = useState<any[]>(bankData);

  const options = dataBankOption.map((d: any) => (
    <Option key={d.shortName} value={d.vn_name}>
      {`${d.vn_name} (${d.shortName})`}
    </Option>
  ));

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fake(newValue, setDataBankOption);
    } else {
      setDataBankOption(bankData);
    }
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Form
        layout="vertical"
        name="updateUser"
        initialValues={data}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        {/* <Form.Item
          label={t('bank.list.userName')}
          name="user_name"
          rules={[{ required: true, message: 'Please enter user name!' }]}
        >
          <InputSearchUserName
            valueUserName={valueUserName}
            setValueUserName={setValueUserName}
          />
        </Form.Item> */}
        <Form.Item
          label={t('bank.list.bankName')}
          name="bank_name"
          rules={[{ required: true, message: 'Please enter bank name!' }]}
        >
          <Select
            showSearch
            value={value}
            // placeholder={props.placeholder}
            // style={props.style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('bank.list.bankNumber')}
          name="bank_number"
          rules={[
            { required: true, message: 'Please enter file bank number!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('bank.list.bankHolder')}
          name="bank_holder"
          rules={[
            { required: true, message: 'Please enter file bank holder!' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item className="staff-item" {...tailLayout}>
          <BackButton />
          <SaveButton htmlType="submit" />
        </Form.Item>
      </Form>
    ),
  };
  console.log('data', data);
  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {data && (
            <>
              <InfoWithAvatar
                id={id}
                data={[{ label: 'User name', value: data.user_name }]}
                loading={loading}
              />
            </>
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditBank;
