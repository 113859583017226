import { Card, Col, Divider, notification, Row } from 'antd';
import { t } from 'i18n';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import { SALE_MAN } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { PATH } from 'routes/constants';
import { bankData } from './dataBank';
const tabList = [
  {
    key: 'info',
    tab: t('bank.detail.title'),
  },
];

type Params = {
  id: string;
};

const DetailBank = () => {
  const router = useRouter<Params>();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const [url, setUrl] = useState('');

  useEffect(() => {
    const role = getCookie('author_user');
    if (role) {
      if (role === SALE_MAN) {
        setUrl('/api/v1/manager/bank_info/get_mine');
      } else {
        setUrl('/api/v1/manager/bank_info/get');
      }
    }
  }, [getCookie('author_user')]);

  useEffect(() => {
    if (data) {
      const bankItem = bankData.filter((bank: any) => {
        return (
          bank.vn_name.toLowerCase().indexOf(data.bank_name.toLowerCase()) >
            -1 ||
          bank.shortName.toLowerCase().indexOf(data.bank_name.toLowerCase()) >
            -1
        );
      });
    }
  }, [data]);

  const getDetailBank = (code: string) => {
    setLoading(true);
    promotionClient
      .post(url, { id })
      .then((res: any) => {
        if (res.data) {
          const bankItem = bankData.find((bank: any) => {
            return (
              bank.vn_name
                .toLowerCase()
                .indexOf(res.data.item.bank_name.toLowerCase()) > -1 ||
              bank.shortName
                .toLowerCase()
                .indexOf(res.data.item.bank_name.toLowerCase()) > -1
            );
          });

          setData({ ...res.data.item, ...{ shortName: bankItem?.shortName } });
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id && url) {
      getDetailBank(id);
    }
  }, [id, url]);

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Row gutter={[16, 16]}>
        <Col span={6} className="title-detail">
          {t('bank.list.bankName')}
        </Col>
        <Col span={18}>
          {data?.bank_name} ({data?.shortName})
        </Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('bank.list.bankNumber')}
        </Col>
        <Col span={18}>{data?.bank_number}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('bank.list.bankHolder')}
        </Col>
        <Col span={18}>{`${data?.bank_holder}`}</Col>
      </Row>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {data && (
            <>
              <InfoWithAvatar
                id={id}
                data={[{ label: 'User name', value: data.user_name }]}
                loading={loading}
                path={`${PATH.BANK.LIST}/${id}/edit`}
              />
            </>
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailBank;
