import { Form, notification } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import AddGroupBlacklist from './AddGroupBlacklist';
import './Blacklist.scss';
import BlacklistDetail from './BlacklistDetail';
import ListBlackList from './components/ListBlackList';

export default function Blacklist(props: any) {
  const [dataApply, setDataApply] = useState([] as any[]);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.userId && props.tab) {
      getApi();
      getBlacklistApiOfStaff();
    }
  }, [props.userId, props.tab]);

  const AddBlacklistGroup = (values: any) => {
    promotionClient
      .post(props.apiCreate, {
        admin_id: props.userId,
        name: values.name,
        list: [],
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: `Create group blacklist ${values.name} successfully`,
          });
          getApi();
          onReset();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const getApi = () => {
    promotionClient
      .post(props.apiGet, {
        admin_id: props.userId,
      })
      .then((res: any) => {
        if (res.data.total) {
          if (props.type === 'blocklist') {
            setData(res.data.list);
          } else {
            setData(res.data.rules);
          }
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const onReset = () => {
    form.resetFields();
  };

  const getBlacklistApiOfStaff = () => {
    const api =
      props.type === 'blocklist'
        ? '/api/v1/manager/staff_app/blocklist/list'
        : props.type === 'blacklist'
        ? 'api/v1/manager/staff_web/blacklist/list'
        : '/api/v1/manager/staff_web/whitelist/list';
    promotionClient
      .post(api, {
        admin_id: props.userId,
      })
      .then((res: any) => {
        if (res.data.total) {
          const data =
            props.type === 'blocklist'
              ? res.data.staff_app
              : res.data.staff_web;
          if (data.length) {
            const dataTable = data.map((val: any, index: number) => {
              return {
                index: index,
                ...val,
              };
            });
            setDataApply(dataTable);
          } else {
            setDataApply([]);
          }
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  return (
    <div>
      <AddGroupBlacklist
        AddBlacklistGroup={AddBlacklistGroup}
        type={props.type}
      />
      {data &&
        data.map((item, index) => (
          <BlacklistDetail
            getApi={getApi}
            key={index}
            item={item}
            type={props.type}
            apiEditName={props.apiEditName}
            apiDeleteRule={props.apiDeleteRule}
            apiEditDetailRule={props.apiEditDetailRule}
            apiDeleteDetailRule={props.apiDeleteDetailRule}
          />
        ))}

      {['blacklist', 'whitelist', 'blocklist'].includes(props.type) && (
        <div style={{ marginTop: '20px' }}>
          <ListBlackList
            adminId={props.userId}
            type={props.type}
            tab={props.tab}
            dataApply={dataApply}
            dataRule={data}
            getBlacklistApiOfStaff={getBlacklistApiOfStaff}
          />
        </div>
      )}
    </div>
  );
}
