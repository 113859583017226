import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, notification, Pagination, Row, Table, Tabs } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { formatter } from '../Promotion/Promotions';
import './Sale.scss';

const Sales = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    order: 'asc',
    page: 1,
    page_size: 10,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const { TabPane } = Tabs;

  useEffect(() => {
    getUsers(pagination);
  }, [pagination]);

  const columns = [
    {
      title: t('promotion.list.no'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: t('promotion.list.code'),
      dataIndex: 'code',
      key: 'code',
      // with: '40%',
      render: (code: any) => <span>{code}</span>,
      // sorter: (a: any, b: any) => a.full_name.length - b.full_name.length,
    },
    {
      title: t('promotion.list.expire'),
      dataIndex: 'expire',
      key: 'expire',
      // with: '30%',
    },
    {
      title: t('promotion.list.percent'),
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: t('promotion.list.money'),
      dataIndex: 'money',
      key: 'money',
      render: (money: string) => <span>{formatter(money)}</span>,
    },
    {
      title: t('promotion.list.unit'),
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: t('promotion.list.month'),
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: t('promotion.list.reward'),
      dataIndex: 'reward',
      key: 'reward',
      render: (reward: string) => <span>{formatter(reward)}</span>,
    },
    {
      title: t('promotion.list.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('promotion.list.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('promotion.list.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: t('promotion.list.updatedAt'),
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: t('promotion.list.action'),
      dataIndex: '',
      key: 'delete',
      render: (item: any, value: any) => (
        <div className="ant-btn-group" style={{ marginLeft: '5px' }}>
          <Button
            icon={<SearchOutlined />}
            onClick={() =>
              history.push(
                `${PATH.PROMOTION.LIST}/${value.id}/${value.code}/sale`
              )
            }
          />
          <Button
            icon={<EditOutlined />}
            // onClick={() =>
            //   history.push(
            //     `${PATH.PROMOTION.LIST}/${value.id}/${value.code}/edit`
            //   )
            // }
          />
        </div>
      ),
    },
  ];

  const getUsers = (params: any) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/promotion/list_mine`, params)
      .then((res: any) => {
        if (res.data.total) {
          setUsers(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (page: number, pageSize?: number) => {
    setPagination({
      ...pagination,
      page: page,
      page_size: pageSize || pagination.page_size,
    });
  };

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Sales" key="1">
          <Row gutter={15}>
            <Col className="gutter-row" lg={24} md={24} xs={24}>
              <div className="table-staff">
                <Table
                  rowKey={(record) => record.id}
                  bordered
                  pagination={false}
                  scroll={{ x: 600 }}
                  dataSource={users}
                  columns={columns}
                  loading={loading}
                />
              </div>
              <div className="user-pagination">
                <Pagination
                  defaultCurrent={pagination.page}
                  pageSize={pagination.page_size}
                  total={500}
                  onChange={handleTableChange}
                />
              </div>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Sales;
