import { Radio, Select } from 'antd';
import { promotionClient } from 'pkg/request/clients';
import React from 'react';
import { useState } from 'react';

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

export const SearchLikeInput: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  handleChange: (user: any) => void;
}> = (props) => {
  const { Option } = Select;
  console.log('props', props);

  const [data, setData] = useState<any[]>([]);
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [typeSearch, setTypeSearch] = useState<string>('parent_id');

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetch(newValue, setData);
    } else {
      setData([]);
    }
  };

  const handleSelect = (newValue: any) => {
    const dataParent = data.find((d) => d.parent_id === newValue);
    props.handleChange(dataParent);
  };

  const options = data.map((d) => {
    return (
      <Option key={d.parent_id} value={d.parent_id}>
        {d[typeSearch]}
      </Option>
    );
  });

  const fetch = (value: string, callback: (data: any) => void) => {
    setLoading(true);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    const fake = () => {
      promotionClient
        .post('/api/v1/manager/user/search_like', {
          keyword: value,
          type: typeSearch,
        })
        .then((res: any) => {
          callback(res.data.list);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    timeout = setTimeout(fake, 300);
  };

  return (
    <div>
      Search:{' '}
      <Select
        showSearch
        value={value}
        placeholder={props.placeholder}
        style={props.style}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleSelect}
        notFoundContent={null}
        loading={loading}
      >
        {options}
      </Select>
      <Radio.Group
        onChange={(e) => setTypeSearch(e.target.value)}
        defaultValue="parent_id"
        style={{ marginLeft: '30px' }}
        buttonStyle="solid"
      >
        <Radio.Button value="parent_id">Parent ID</Radio.Button>
        <Radio.Button value="parent_email">Email</Radio.Button>
        <Radio.Button value="parent_phone">Phone</Radio.Button>
      </Radio.Group>
    </div>
  );
};
