import { Card, Col, Form, notification, Row, Select } from 'antd';
import { t } from 'i18n';
import { BackButton, SaveButton } from 'modules/base/Buttons';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
const tabList = [
  {
    key: 'info',
    tab: t('rule.edit.title'),
  },
];
const tailLayout = {
  wrapperCol: { span: 17 },
};
const { Option } = Select;

type Params = {
  id: string;
};

const EditRule = () => {
  const router = useRouter<Params>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [listName, setListName] = useState<any>([]);

  const getDetailBrowser = (id: string) => {
    setLoading(true);
    promotionClient
      .post('/api/v1/manager/app_rule/blocklist/get', { id })
      .then((res: any) => {
        if (res.data) {
          setData(res.data.item);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getListName = () => {
    setLoading(true);
    promotionClient
      .get('/api/v1/manager/app_info/list_name')
      .then((res: any) => {
        if (res.data) {
          setListName(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getDetailBrowser(id);
      getListName();
    }
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const onFinishFailed = (val: any) => {};

  const onSubmit = (val: any) => {
    const data = {
      ...val,
      id,
    };
    setLoading(true);
    promotionClient
      .post('/api/v1/manager/app_rule/blocklist/edit', data)
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Update browser success',
          });
          history.push(`${PATH.RULE.LIST}/${id}`);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (value: string) => {
    console.log('value', value);
  };

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Form
        layout="vertical"
        name="updateUser"
        initialValues={{ list: data?.list || [] }}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item className="staff-item" name="list">
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="select rule"
            onChange={handleChange}
            optionLabelProp="label"
          >
            {listName.map((item: any) => (
              <Option value={item}>
                <div className="demo-option-label-item">{item}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="staff-item" {...tailLayout}>
          <BackButton />
          <SaveButton htmlType="submit" />
        </Form.Item>
      </Form>
    ),
  };
  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {data && <InfoWithAvatar id={id} data={[]} loading={loading} />}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditRule;
