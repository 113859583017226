import { Pagination, Table } from 'antd';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getToday, handleToDay } from './action';
import ButtonSelectDay from './ButtonSelectDay';

type Props = {
  getAdminId: () => void;
  getTimeZone: () => void;
  agentId: string;
};

const TabApp = ({ getAdminId, getTimeZone, agentId }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dayApp, setDayApp] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  let params: any = useParams();

  useEffect(() => {
    getApiList();
  }, []);

  let columns = [
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
    },
    {
      title: 'Application',
      dataIndex: 'app_name',
      key: 'app_name',
    },
    {
      title: 'Start time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'Stop time',
      dataIndex: 'stop_time',
      key: 'stop_time',
    },
    {
      title: 'Usage time',
      dataIndex: 'usage_time',
      key: 'usage_time',
    },
  ];

  useEffect(() => {
    document.querySelector('div.ant-table-body')!.scrollTop = 0;
  }, [dataSource]);

  const [pageNumber, setPageNumber] = useState<any>(1);

  const getApiList = () => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/app_usage/list`, {
        admin_id: params.parent_id,
        agent_id: params.agent_id,
        sort: 'desc',
        date: getToday(),
        top: 50,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        setDataSource(res.data.apps);
        setLoading(false);
      });
  };

  const handleSubmitApp = (number: number) => {
    setPageNumber(1);
    setLoading(true);
    setDayApp(number);
    promotionClient
      .post(`/api/v1/manager/app_usage/list`, {
        admin_id: getAdminId(),
        agent_id: agentId,
        sort: 'desc',
        date: handleToDay(number),
        top: 50,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        setDataSource(res.data.apps);
        setLoading(false);
      });
  };

  function onShowSizeChangeApp(current: any, pageSize?: any) {
    // setSaveCurrentPage(current);
    setPageNumber(current || 1);
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/app_usage/list`, {
        admin_id: getAdminId(),
        agent_id: agentId,
        sort: 'desc',
        date: handleToDay(dayApp),
        top: 50,
        page: current || 1,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        if (res.data) {
          setLoading(false);
          setDataSource(res.data.apps);
        }
      });
  }

  return (
    <div>
      <ButtonSelectDay actionFunction={handleSubmitApp} />
      <Table
        scroll={{ x: 600, y: 'calc(100vh - 27em)' }}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={loading}
        rowKey={(record: any) => record.id}
      />
      {dataSource.length > 0 && (
        <Pagination
          current={pageNumber}
          style={{ marginTop: '20px' }}
          onChange={onShowSizeChangeApp}
          defaultPageSize={1}
          defaultCurrent={1}
          total={50}
        />
      )}
    </div>
  );
};

export default TabApp;
