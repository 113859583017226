import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, notification, Popconfirm, Table } from 'antd';
import AddStaffByAgent from 'modules/Dashboard/userManagement/AddStaffByAgent';
import UserManagement from 'modules/Dashboard/userManagement/User';
import React, { useEffect, useState } from 'react';
import { t } from '../../../../i18n';
import { promotionClient } from '../../../../pkg/request/clients';
import './Information.scss';
type Props = {
  user: any;
  tab: string;
};
const { Panel } = Collapse;
export default function ChildrenTab({ user, tab }: Props) {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (user && tab === '7') {
      getList();
    }
  }, [user, tab]);

  const getList = () => {
    promotionClient
      .post(`/api/v1/manager/staff_agent/list`, {
        admin_id: user,
      })
      .then((res: any) => {
        if (res.data.total) {
          setData(res.data.staff_agent.filter((item: any) => item.enable));
        } else {
          setData([]);
        }
      });
  };

  const handleActiveAction = (agent_id: string, staff_id: string) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/staff_agent/active`, {
        agent_id,
        staff_id,
        admin_id: user,
      })
      .then((res: any) => {
        setLoading(false);
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Login successfully',
          });
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        setLoading(false);
        notification.error({
          message: t('Error'),
          description: err.message,
        });
      });
  };

  const handleDeleteStaff = (staff: any) => {
    promotionClient
      .post('/api/v1/manager/staff_agent/delete', {
        staff_id: staff.staff_id,
        admin_id: user,
        agent_id: staff.agent_id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Delete user successfully',
          });
          getList();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  let columns = [
    {
      title: 'ID',
      render: (avatar: any, item: any, index: number) => index + 1,
    },
    {
      title: 'User',
      dataIndex: 'staff_name',
      key: 'staff_name',
      sorter: (a: any, b: any) => a.staff_name.length - b.staff_name.length,
    },
    {
      title: 'Agent Name',
      dataIndex: 'agent_name',
      key: 'agent_name',
      sorter: (a: any, b: any) => a.agent_name.length - b.agent_name.length,
      render: (value: any, staff: any) => {
        return (
          <span>
            {staff.agent_name === '' ? staff.agent_id : staff.agent_name}
          </span>
        );
      },
    },
    {
      title: 'Login',
      key: 'login',
      render: (value: any, staff: any) => {
        const handleActive = () => {
          handleActiveAction(staff.agent_id, staff.staff_id);
        };

        return (
          <>
            <Button type="primary" onClick={() => handleActive()}>
              Login
            </Button>
            <Popconfirm
              placement="topRight"
              title={'Delete User'}
              onConfirm={() => handleDeleteStaff(staff)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>{t('userManagement.delete')}</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <div className="site-card-border-less-wrapper">
      <UserManagement user={user} tab={tab} />
      <div className="list-staff-item" style={{ marginBottom: 10 }}>
        <Collapse
          style={{ border: '2px solid #055fac', maxWidth: '500px' }}
          defaultActiveKey={'0'}
          accordion
          expandIcon={({ isActive }) => (
            <PlusCircleOutlined
              style={{ fontSize: '120%', color: '#055fac' }}
              rotate={isActive ? 130 : 0}
            />
          )}
        >
          <Panel header={t('userManagement.addUserToAgent.title')} key="2">
            <AddStaffByAgent parentId={user} getList={getList} />
          </Panel>
        </Collapse>
      </div>
      {data.length > 0 && (
        <Table
          scroll={{ x: 600 }}
          dataSource={data}
          columns={columns}
          pagination={false}
          loading={loading}
          rowKey={(record: any) => `${record.agent_id}-${record.staff_id}`}
        />
      )}
    </div>
  );
}
