import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { StoreContext } from 'contexts';
import defaultSellerLogo from 'assets/images/android-chrome-128x128.png';
import { PATH } from 'routes/constants';
import { TABLET_WIDTH } from 'pkg/device/constants';
import { useWindowDimensions } from 'pkg/device/hooks';
import { IRoute } from 'pkg/route/types';
import { browserHistory } from 'pkg/route/helper';
import { isDesktop } from 'react-device-detect';
import { relative } from 'path';
import { getCookie } from 'pkg/cookie/helpers';

const { Sider, Footer } = Layout;
const { SubMenu } = Menu;

interface AppSiderProps {
  filteredNavigation: Array<IRoute>;
}

let autoCollapse = true;

const AppSider: React.FC<AppSiderProps> = (props) => {
  // Get selectedKey, openKey from route & pathname
  const { filteredNavigation } = props;
  const { location } = browserHistory;
  let selectedKey = location.pathname;
  const selectedKeySplitArr = location.pathname.split('/');
  let newSelectedKey = '';
  let i = 1;
  const getParentKey: (key: string) => IRoute | undefined = (key) => {
    const newParentKey = filteredNavigation.find(
      (item) => item.children && item.children.includes(key)
    );
    if (newParentKey) return newParentKey;
    else if (i < selectedKeySplitArr.length) {
      newSelectedKey += `/${selectedKeySplitArr[i++]}`;
      selectedKey = newSelectedKey;
      return getParentKey(selectedKey);
    }
  };
  const parentKey = getParentKey(selectedKey);
  const openKey = parentKey ? parentKey.path : PATH.HOME;

  // Get logo from seller info
  const { sellerInfo = {} } = useContext(StoreContext);
  const logoUrl = sellerInfo.logoUrl || defaultSellerLogo;

  const { width } = useWindowDimensions();
  const [collapsed, setCollapsed] = useState(false);
  const [isHiddenMenuMobile, setIsHiddenMenuMobile] = useState(false);
  const [roleCurrentUser, setRoleCurrentUser] = useState(
    getCookie('author_user')
  );

  useEffect(() => {
    setRoleCurrentUser(getCookie('author_user'));
  }, []);

  const toggle = () => {
    autoCollapse = false;
    setCollapsed((collapsed) => !collapsed);
    // setIsHiddenMenuMobile(true)
  };

  useEffect(() => {
    if (autoCollapse) {
      setCollapsed(width <= TABLET_WIDTH);
    }
  }, [width]);

  const RenderTooglePC = () => {
    if (collapsed) {
      return (
        <MenuUnfoldOutlined
          data-testid="menu-expand-icon"
          className="app-icon app-trigger"
          onClick={toggle}
        />
      );
    }

    return (
      <MenuFoldOutlined
        data-testid="menu-collapse-icon"
        className="app-icon 1 app-trigger"
        onClick={toggle}
      />
    );
  };

  const toggleMobile = () => {
    setIsHiddenMenuMobile(!isHiddenMenuMobile);
  };
  return (
    <div style={{ background: '#1c222b', position: 'relative' }}>
      {!isDesktop && (
        <div
          style={{ left: `${isHiddenMenuMobile ? '10px' : '85px'}` }}
          className="collapsed-mobile"
        >
          <MenuUnfoldOutlined
            data-testid="menu-expand-icon"
            className="app-icon app-trigger"
            onClick={toggleMobile}
          />
        </div>
      )}
      <Sider
        className={`siderMb app-sider${collapsed ? ' collapsed' : ''} ${
          isHiddenMenuMobile ? 'd-none' : ''
        }`}
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={270}
      >
        {isDesktop && <RenderTooglePC />}

        {logoUrl && (
          <div className="app-logo">
            <Link to={PATH.HOME}>
              <img src={logoUrl} alt="logo" />
            </Link>
          </div>
        )}

        <Menu
          className="app-menu"
          theme="dark"
          mode="inline"
          defaultOpenKeys={[openKey]}
          selectedKeys={[selectedKey]}
        >
          {filteredNavigation.map((item: any) => {
            if (!item.icon || !item.role.includes(roleCurrentUser)) return null;
            if (!item.children) {
              return (
                <Menu.Item className="app-menu-item" key={item.path}>
                  {item.component ? (
                    <Link to={item.path}>
                      <item.icon className="app-icon haha" />
                      <span>{item.name}</span>
                    </Link>
                  ) : (
                    <a href={window.REACT_APP_PROMOTION_DOMAIN + item.path}>
                      {/* <item.icon className="app-icon" />
                      <span>{item.name}</span> */}
                    </a>
                  )}
                </Menu.Item>
              );
            } else {
              const { children } = item;
              const childs = filteredNavigation.filter(
                (child) => children.includes(child.path) && !child.children
              );
              return (
                <SubMenu
                  key={item.path}
                  title={
                    <span>
                      <item.icon className="app-icon hehe" />
                      <span>{item.name}</span>
                    </span>
                  }
                >
                  {childs.map((child) => {
                    return (
                      <Menu.Item key={child.path}>
                        {child.component ? (
                          <Link to={child.path}>{child.name}</Link>
                        ) : (
                          <a
                            href={
                              window.REACT_APP_PROMOTION_DOMAIN + child.path
                            }
                          >
                            {child.name}
                          </a>
                        )}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </Menu>

        {!collapsed && (
          <Footer className="app-footer">
            SafeWeb © {process.env.REACT_APP_VERSION}
          </Footer>
        )}
      </Sider>
    </div>
  );
};

export default AppSider;
