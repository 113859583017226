import { getCookie, setCookie } from '../cookie/helpers';
import { REGIONS } from './constants';

export const LOCALIZATION = 'sc_localization';

export const getCurrentLanguage = () => {
  return getCookie(LOCALIZATION) || REGIONS.en.key;
};

export const changeLanguage = (newLanguage: string) => {
  setCookie(LOCALIZATION, newLanguage, 365);
  window.location.reload();
};
