import {
  ChromeOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  notification,
} from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useRef, useState } from 'react';
import { MobileView } from 'react-device-detect';
import './Blacklist.scss';
let originData: any[] = [];

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const randomListWebsite = () => {
  let numberRamdom = randomIntFromInterval(0, 3);
  const arr = [
    `https://baophapluat.vn/`,
    'https://facebook.com/',
    'https://zalo.com/',
    'https://safeweb.app/',
  ];
  return arr[numberRamdom];
};

for (let i = 0; i < 3; i++) {
  originData.push({
    key: i.toString(),
    name: randomListWebsite(),
  });
}

const EditableCell = (props: any) => {
  const [options, setOptions] = useState<any>([]);
  useEffect(() => {
    if (props.listName && props.listName.length > 0) {
      const o = props.listName.map((item: any, index: number) => {
        return { label: item, value: index };
      });

      setOptions(o);
    }
  }, [props.listName]);
  const [optionsShow, setOptionsShow] = useState(options);

  const handleOnSearch = (searchText: string) => {
    const data = options
      .filter((obj: any) =>
        obj.label.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((item: any, index: number) => {
        const isDuplicate = props.data.some((obj: any, i: any) => {
          return obj.name === item.label;
        });
        return { ...item, isDuplicate };
      });
    setOptionsShow(data);
  };

  const [errorMessage, setErrorMessage] = useState('');
  const combineDomainUrl = (eventChange: any) => {
    const value = eventChange.target.value;
    const domainRegex =
      /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
    const match = value.match(domainRegex);
    if (match) {
      setErrorMessage('');
      props.data.forEach((element: any) => {
        if (element.name === value) {
          setErrorMessage(t('duplicate'));
          return;
        }
      });
    } else {
      setErrorMessage(t('url.incorrect'));
    }
  };

  return (
    <td>
      {props.editing ? (
        <>
          {props.type === 'blocklist' && (
            <Form.Item
              name={props.dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${props.title}!`,
                },
              ]}
            >
              <AutoComplete
                options={props.type === 'blocklist' ? optionsShow : []}
                onSelect={props.handleOnSelect}
                onSearch={handleOnSearch}
                placeholder="input here"
              />
            </Form.Item>
          )}
          {['blacklist', 'whitelist'].includes(props.type) && (
            <Form.Item
              name={props.dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${props.title}!`,
                },
              ]}
            >
              <Input onChange={combineDomainUrl} />
            </Form.Item>
          )}
          <span style={{ color: 'red' }}>{errorMessage}</span>
        </>
      ) : (
        props.children
      )}
    </td>
  );
};

export default function Blacklist(props: any) {
  useEffect(() => {
    convertArr();
  }, [props.item]);

  const [form] = Form.useForm();
  const [formChangeName] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const { Panel } = Collapse;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isEditing = (record: any) => record.key === editingKey;

  const convertArr = () => {
    let array = [];
    for (let i = 0; i < props.item.list.length; i++) {
      array.push({
        key: i,
        name: props.item.list[i],
      });
    }
    setData(array);
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      name: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const handleDelete = (record: any) => {
    let findArray = data.filter((item) => {
      return item.key !== record.key;
    });
    let updateData = findArray.map((item: any, index) => {
      item.key = index;
      return item;
    });

    let arrString: any = [];
    arrString = updateData.map((item: any) => item.name);

    setData(updateData);

    promotionClient
      .post(props.apiDeleteDetailRule, {
        id: props.item.id,
        list: arrString,
      })
      .then((res: any) => {
        if (res.data.success) {
          props.getApi();
          notification.success({
            message: t('Success'),
            description: `Update group ${record.name} successfully`,
          });
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const save = async (record: any, isDelete: string) => {
    let arrString: any = [];
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => record.key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');

        arrString = newData.map((item: any) => item.name);
        promotionClient
          .post(props.apiEditDetailRule, {
            id: props.item.id,
            list: arrString,
          })
          .then((res: any) => {
            if (res.data.success) {
              props.getApi();
              notification.success({
                message: t('Success'),
                description: `Update group  ${record.name} successfully`,
              });
            } else {
              props.getApi();
              notification.error({
                message: 'Error',
                description: res.data.message,
              });
            }
          })
          .catch((err: any) => {
            notification.error({
              message: t('Error'),
              description: err || 'error',
            });
          });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('rule.list.no'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      render: (value: any, staff: any, index: any) => {
        if (!['blacklist', 'whitelist', 'blocklist'].includes(props.type)) {
          return (
            <>
              <span>{value}</span>
              {staff.isDuplicate && (
                <div style={{ color: 'red' }}>{t('duplicate')}</div>
              )}
            </>
          );
        } else if (props.type === 'blocklist') {
          return (
            <>
              <span>{value}</span>
              {staff.isDuplicate && (
                <div style={{ color: 'red' }}>{t('duplicate')}</div>
              )}
            </>
          );
        } else {
          const domainRegex =
            /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
          const match = value.match(domainRegex);
          return (
            <div>
              {value}
              {!match && (
                <div style={{ color: 'red' }}>{t('url.incorrect')}</div>
              )}
              {staff.isDuplicate && (
                <div style={{ color: 'red' }}>{t('duplicate')}</div>
              )}
            </div>
          );
        }
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: MobileView ? '45%' : 100,
      render: (value: any, record: { key: any }) => {
        const editable = isEditing(record);
        return (
          <div>
            {editable ? (
              <span className="td-common-action">
                <Button
                  type="primary"
                  ghost
                  className="td-common-action-item"
                  onClick={() => save(record, 'edit')}
                  style={{
                    marginRight: 8,
                    marginBottom: 10,
                  }}
                >
                  Save
                </Button>
                <Button
                  className="td-common-action-item"
                  onClick={cancel}
                  style={{
                    marginRight: 8,
                    marginBottom: 10,
                  }}
                >
                  Cancel
                </Button>
              </span>
            ) : (
              <Button
                className="td-common-action-item"
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                style={{
                  marginRight: 8,
                  marginBottom: 10,
                }}
              >
                Edit
              </Button>
            )}

            <Button
              className="td-common-action-item"
              disabled={false}
              style={{ marginLeft: !MobileView ? '10px' : '' }}
              onClick={() => handleDelete(record)}
              danger
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const currentCol = useRef<any>(null);

  const handleOnSelect = (item: any) => {
    const data = listName.find((name: any, index: number) => index == item);
    form.setFields([
      {
        name: currentCol.current.dataIndex,
        value: data,
      },
    ]);
  };

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    currentCol.current = col;

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'time' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        listName,
        handleOnSelect,
        // handleOnSearch,
        value: form.getFieldValue(col.dataIndex),
        type: props.type,
        data,
      }),
    };
  });

  const handleAdd = () => {
    const newData = {
      key: data.length + 1 || 1,
      name: `${props.type === 'blocklist' ? '' : 'example.com'}`,
    };
    let arr = [newData, ...data];
    setData(arr);
    edit(newData);
  };

  const EditBlackListName = (value: any) => {
    promotionClient
      .post(props.apiEditName, {
        id: props.item.id,
        name: value.blacklistName,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'update successfully',
          });
          props.getApi();
          setIsModalVisible(false);
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleDeleteUser = () => {
    promotionClient
      .post(props.apiDeleteRule, {
        id: props.item.id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Delete successfully',
          });
          props.getApi();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [listName, setListName] = useState<any>([]);
  useEffect(() => {
    getListName();
  }, []);

  const getListName = () => {
    promotionClient
      .get('/api/v1/manager/app_info/list_name')
      .then((res: any) => {
        if (res.data) {
          setListName(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err) => {});
  };

  // const handleOnSearch = (value: any) => {
  //   form.setFields([
  //     {
  //       name: currentCol.current.dataIndex,
  //       value: value,
  //     },
  //   ]);
  // };

  const mapData: any = () => {
    const output = data.map((item, index, arr) => {
      const isDuplicate = arr.some(
        (obj, i) => obj.name === item.name && i !== index
      );
      return { ...item, isDuplicate };
    });

    return output;
  };

  return (
    <>
      <div style={{ marginTop: '10px' }} className={'blacklist-detail-main'}>
        <div className={'blacklist-detail-main-ab'}>
          <Popconfirm
            placement="topRight"
            title={'Delete item'}
            onConfirm={() => handleDeleteUser()}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: 'red', fontSize: 25 }} />
          </Popconfirm>
        </div>
        <div className={'blacklist-detail-edit'}>
          <EditOutlined
            onClick={() => showModal()}
            style={{ fontSize: '150%' }}
          />
        </div>
        <Collapse
          defaultActiveKey={'0'}
          accordion
          expandIcon={({ isActive }) => (
            <ChromeOutlined
              twoToneColor="#52c41a"
              style={{ fontSize: '120%' }}
              rotate={isActive ? 130 : 0}
            />
          )}
        >
          <Panel header={props.item.name} key="1">
            {/* <AddBlacklistForm AddBlacklist={AddBlacklist} /> */}
            <Form form={form} className={'mt-3'} component={false}>
              <Button
                onClick={handleAdd}
                type="primary"
                style={{
                  marginBottom: 16,
                }}
              >
                {`${
                  props.type === 'blocklist' ? 'Add an app' : 'Add a website'
                }`}
              </Button>
              <Table
                scroll={{ x: 600 }}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={mapData()}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
          </Panel>
        </Collapse>

        <Modal
          title="Update blacklist name"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            form={formChangeName}
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ blacklistName: props.item.name }}
            onFinish={(value: any) => EditBlackListName(value)}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Blacklist name"
              name="blacklistName"
              rules={[
                {
                  required: true,
                  message: 'Please input your blacklist name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button type="primary" className="mt-1" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}
