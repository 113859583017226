import { Button, Col, notification, Pagination, Row, Table } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { handleToDay } from './action';
import ButtonSelectDay from './ButtonSelectDay';

type Props = {
  getAdminId: () => void;
  getTimeZone: () => void;
  agentId: string;
};
const truncate = (str: any, number: number) => {
  return str.length > number ? str.substring(0, number) + ' ...' : str;
};

const TabUrl = ({
  getAdminId,
  getTimeZone,
  // getToday,
  agentId,
}: Props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [day, setDay] = useState<number>(0);
  const [dataSourceUrl, setDataSourceUrl] = useState([]);

  let columnsUrl = [
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
      width: '80px',
    },
    {
      title: 'Urls',
      dataIndex: 'url',
      key: 'url',
      render: (item: any, value: any) => RenderUrl(item, value),
    },
    {
      title: 'Start time',
      dataIndex: 'start_time',
      key: 'start_time',
      width: '220px',
    },
  ];
  useEffect(() => {
    getUrls();
  }, [agentId]);

  useEffect(() => {
    document.querySelector('div.ant-table-body')!.scrollTop = 0;
  }, [dataSourceUrl]);

  const [pageNumber, setPageNumber] = useState<any>(1);
  const getUrls = (pageNumber?: any) => {
    setPageNumber(pageNumber || 1);

    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/url_request/list`, {
        admin_id: getAdminId(),
        agent_id: agentId,
        sort: 'desc',
        date: handleToDay(day),
        top: 50,
        page: pageNumber || 1,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        if (res.data.total.length !== '0') {
          setDataSourceUrl(res.data.urls);
          setLoading(false);
        }

        // if (res.data.urls.length > 0) {
        //   setIsShowPagingApps(true);
        // }
      });
  };
  const RenderUrl = (item: any, value: any) => {
    const description = value.description || 'No description';
    const title = value.domain || 'No title';

    return (
      <Row style={{ width: '100%' }}>
        <Col md={18} sm={24}>
          <h3 className="url-title">{truncate(title, 80)}</h3>
          <a target="_blank" href={value.url} className="urls">
            {truncate(value.url, 100)}{' '}
          </a>
        </Col>
        <Col md={6} sm={24}>
          <Button onClick={() => handleCopy(value.url)} className="url-copy">
            Copy url
          </Button>
        </Col>
      </Row>
    );
  };
  const handleCopy = (url: any) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: t('Success'),
      description: 'Copy Success',
    });
  };
  const handleSubmitURLs = (number?: any, pageNumber?: any) => {
    setPageNumber(1);
    setLoading(true);
    setDay(number);
    promotionClient
      .post(`/api/v1/manager/url_request/list`, {
        admin_id: getAdminId(),
        agent_id: agentId,
        sort: 'desc',
        date: handleToDay(number),
        top: 50,
        page: pageNumber || 1,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        if (res.data.total.length !== '0') {
          setDataSourceUrl(res.data.urls);
          setLoading(false);
        }
      });
  };
  return (
    <div>
      <ButtonSelectDay actionFunction={handleSubmitURLs} />
      <Table
        scroll={{ x: 600, y: 'calc(100vh - 27em)' }}
        dataSource={dataSourceUrl}
        columns={columnsUrl}
        pagination={false}
        loading={loading}
        rowKey={(record: any) => record.id}
        // pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
      />
      {dataSourceUrl.length > 0 && (
        <Pagination
          current={pageNumber}
          style={{ marginTop: '20px' }}
          onChange={(pageNumber: any) => getUrls(pageNumber)}
          defaultPageSize={1}
          defaultCurrent={1}
          total={50}
        />
      )}
    </div>
  );
};

export default TabUrl;
