import { Card, Progress } from 'antd';
import { t } from 'i18n';
import moment, { now } from 'moment';
import React, { useEffect, useState } from 'react';

interface License {
  begin: string;
  end: string;
  title: string;
}

type Props = {
  loading: boolean;
  license: License;
};

const formatDate = 'YYYY-MM-DD';

const LicenseStatus = ({ loading, license }: Props) => {
  const [percent, setPercent] = useState<number>(100);
  useEffect(() => {
    countLicense(license);
  }, [license]);

  const countLicense = (license: any) => {
    const start = moment(license.begin, formatDate);
    const end = moment(license.end, formatDate);
    const nowDate = moment(moment(), formatDate);
    const diff = end.diff(start, 'days');
    const diffNow = nowDate.diff(start, 'days');
    if (diff > 0 && diffNow < diff) {
      const countLicense = ((diffNow / diff) * 100).toFixed(2);
      setPercent(+countLicense);
    }
  };

  return (
    <Card
      title={license.title}
      loading={loading}
      style={{ width: '100%', marginTop: '20px' }}
    >
      <div className="progress">
        <div className="start">{moment(license.begin).format(formatDate)}</div>
        <div className="start">{moment(license.end).format(formatDate)}</div>
      </div>
      <Progress percent={percent} />
    </Card>
  );
};

export default LicenseStatus;
