import { RedoOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, PageHeader, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from '../../../../i18n';
import { promotionClient } from '../../../../pkg/request/clients';
// import './Information.scss';
type Props = {
  user: any;
};

export default function Setting({ user }: Props) {
  const [agents, setAgents] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      getList();
    }
  }, [user]);

  const getList = () => {
    promotionClient
      .post(`/api/v1/manager/agent_access/list`, {
        admin_id: user,
      })
      .then((res: any) => {
        if (res.data.total) {
          setAgents(res.data.list);
        } else {
          setAgents([]);
        }
      });
  };

  return (
    <div className="site-card-border-less-wrapper">
      <Row>
        {agents.map((item: any, index: number) => {
          return (
            <Col
              key={index}
              sm={24}
              md={8}
              className="information-page-bg ghost"
            >
              <PageHeader
                ghost={false}
                title={t('children.setting.agentId') + ': ' + item.agent_id}
              >
                <Descriptions size="small" column={1}>
                  <Descriptions.Item label={t('children.setting.name')}>
                    {item.name}
                  </Descriptions.Item>

                  <Descriptions.Item label={t('children.setting.userId')}>
                    {item.user_id}
                  </Descriptions.Item>
                </Descriptions>
                <div className="list-command-action">
                  <Button
                    icon={<RedoOutlined />}
                    className={'mr-1 mt-1 mb-1'}
                    onClick={() =>
                      history.push(`/agentpage/${item.agent_id}/${user}/tabs/2`)
                    }
                    type="primary"
                    shape="round"
                    size={'small'}
                  >
                    History
                  </Button>
                </div>
              </PageHeader>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
