import React from 'react';
import { Button } from 'antd';
import { UpOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';
import {
  SearchOutlined,
  SyncOutlined,
  DownloadOutlined,
  UploadOutlined,
  RollbackOutlined,
  UnorderedListOutlined,
  EditOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { logout } from 'pkg/user/repo';
import { swapArrayElements } from 'pkg/array/helpers';
import { browserHistory } from 'pkg/route/helper';
import { t } from 'i18n';

export const RefreshButton: React.FC<ButtonProps> = (props) => (
  <Button data-testid="btn-refresh" icon={<SyncOutlined />} {...props}>
    {t('Refresh')}
  </Button>
);

export const SearchButton: React.FC<ButtonProps> = (props) => (
  <Button
    data-testid="btn-search"
    type="primary"
    htmlType="submit"
    icon={<SearchOutlined />}
    {...props}
  >
    {t('Search')}
  </Button>
);

export const NextButton: React.FC<ButtonProps> = (props) => (
  <Button data-testid="btn-next" type="primary" {...props}>
    {t('Next')}
  </Button>
);

export const CancelButton: React.FC<ButtonProps> = (props) => (
  <Button data-testid="btn-cancel" {...props}>
    {t('Cancel')}
  </Button>
);

export const SaveButton: React.FC<ButtonProps> = (props) => (
  <Button data-testid="btn-save" type="primary" {...props}>
    {t('Save')}
  </Button>
);

export const DownloadButton: React.FC<ButtonProps> = (props) => (
  <Button data-testid="btn-download" icon={<DownloadOutlined />} {...props} />
);

export const ImportButton: React.FC<ButtonProps> = (props) => (
  <Button
    data-testid="btn-import"
    type="primary"
    icon={<UploadOutlined />}
    {...props}
  >
    Import
  </Button>
);

export const BackButton: React.FC<ButtonProps> = (props) => (
  <Button
    data-testid="btn-back"
    icon={<RollbackOutlined />}
    onClick={() => browserHistory.goBack()}
    {...props}
  >
    {t('Back')}
  </Button>
);

export const ListButton: React.FC<ButtonProps> = (props) => (
  <Button data-testid="btn-list" icon={<UnorderedListOutlined />} {...props} />
);

export const EditButton: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <Button
    data-testid="btn-edit"
    type="primary"
    icon={<EditOutlined />}
    {...rest}
  >
    {children || t('Edit')}
  </Button>
);

export const AddButton: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <Button
    data-testid="btn-add"
    type="primary"
    icon={<PlusOutlined />}
    {...rest}
  >
    {children || t('AddNew')}
  </Button>
);

export const DeleteButton: React.FC<ButtonProps> = (props) => (
  <Button
    data-testid="btn-remove"
    type="link"
    icon={
      <MinusCircleOutlined
        className="clickable-icon"
        style={{ fontSize: 20 }}
      />
    }
    {...props}
  />
);

export const PrevButton: React.FC<ButtonProps> = (props) => (
  <Button data-testid="btn-prev" {...props}>
    {t('Previous')}
  </Button>
);

interface ChangeOrderButtonGroupProps {
  index: number;
  data: any[];
  setData: (data: any[]) => void;
  setChangeOrder?: (change: boolean) => void;
}

export const ChangeOrderButtonGroup: React.FC<ChangeOrderButtonGroupProps> = (
  props
) => {
  const { index, data, setData, setChangeOrder = () => {} } = props;

  const handleChangeOrder = (step: number, index: number) => {
    const newIndex = index + step;
    if (newIndex < 0 || newIndex >= data.length) return;
    setChangeOrder(true);
    const newData = [...data];
    swapArrayElements(newData, index, newIndex);
    setData(newData);
  };

  return (
    <div className="d-inline-flex">
      <Button
        data-testid="up-btn"
        icon={<UpOutlined />}
        className="mr-half"
        onClick={() => handleChangeOrder(-1, index)}
      />
      <Button
        data-testid="down-btn"
        icon={<DownOutlined />}
        onClick={() => handleChangeOrder(1, index)}
      />
    </div>
  );
};

export const LogoutButton: React.FC<ButtonProps> = (_) => (
  <Button icon={<LogoutOutlined />} onClick={logout}>
    {t('Logout')}
  </Button>
);
