import { Button, Form, notification, Select } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';

const AddStaffByAgent = ({ parentId, getList }: any) => {
  const [listYourUser, setListYourUser] = useState([]);
  const [agents, setAgents] = useState<any>([]);

  const { Option } = Select;
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    getListYourUser();
    getListAgent();
  }, [parentId]);

  const getListAgent = () => {
    promotionClient
      .post(`/api/v1/manager/agent_access/list`, {
        admin_id: parentId,
      })
      .then((res: any) => {
        if (res.data.total) {
          const list = res.data.list;
          const options = list.map((item: any) => {
            return {
              value: item.agent_id,
              label: item.agent_id + ' - ' + item.name,
            };
          });
          setAgents(options);
        } else {
          setAgents([]);
        }
      });
  };

  const getListYourUser = () => {
    promotionClient
      .post(`/api/v1/manager/staff_user/list`, {
        admin_id: parentId,
      })
      .then((res: any) => {
        if (res.data.total) {
          setListYourUser(res.data.staff_list);
        }
      });
  };

  const AddStaffByAgent = (item: any) => {
    promotionClient
      .post('/api/v1/manager/staff_agent/create', {
        admin_id: parentId,
        agent_id: item.agent_id,
        staff_id: item.staff_id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Create Staff successfully',
          });
          onReset();
          getList();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      });
  };
  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={AddStaffByAgent}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t('userManagement.addUserToAgent.listYourUser')}
          name="staff_id"
          className="staff-item"
          rules={[{ required: true, message: 'Please select your Staff!' }]}
        >
          {listYourUser && (
            <Select style={{ width: '100%' }}>
              {listYourUser.map((staff: any, index: number) => (
                <Option key={index} value={staff.user_name}>
                  {staff.full_name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={t('userManagement.addUserToAgent.listYourAgent')}
          name="agent_id"
          // className='staff-item'
          rules={[{ required: true, message: 'Please select your Agent!' }]}
        >
          {agents && (
            <Select style={{ width: '100%' }}>
              {agents.map((agent: any, index: number) => (
                <Option key={index} value={agent.value}>
                  {agent.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item className="staff-item mt-10" {...tailLayout}>
          <Button type={'primary'} htmlType="submit">
            {t('userManagement.addUserToAgent.add')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddStaffByAgent;
