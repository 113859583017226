import { Col, Row } from 'antd';
import React from 'react';
import './Dashboard.scss';
const Dashboard = () => {
  // const [loading, setLoading] = useState<boolean>(true);

  // useEffect(function () {
  //   setTimeout(function () {
  //     setLoading(false);
  //   }, 500);
  // }, []);

  // function LoadingCP() {
  //   if (loading) {
  //     return <Loader />;
  //   }
  //   return <div />;
  // }
  return (
    <Row gutter={15}>
      <Col className="gutter-row" span={24}>
        {/* <AddAgent /> */}
      </Col>
      {/* <LoadingCP /> */}
    </Row>
  );
};
export default Dashboard;
