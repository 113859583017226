import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  notification,
  Popconfirm,
  Row,
  Table,
} from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { isDesktop, MobileView } from 'react-device-detect';
import './User.scss';

const UserManagement = ({ user, tab }: any) => {
  const { Panel } = Collapse;
  const [dataSource, setDataSoucre] = useState<any>([]);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: any) => {
    return record.key === editingKey;
  };

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();

  useEffect(() => {
    if (user && tab === '7') {
      getListStaffOfAdminId();
    }
  }, [user, tab]);

  const onReset = () => {
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };
  const edit = (record: any) => {
    formEdit.setFieldsValue({
      name: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const save = async (record: any, isDelete: string) => {
    const row = await formEdit.validateFields();
    const data = { ...record, ...row };
    promotionClient
      .post('/api/v1/manager/staff_user/edit', {
        staff_id: data.id,
        full_name: data.full_name,
      })
      .then((res) => {
        getListStaffOfAdminId();
        setEditingKey('');
      });
  };

  const columns = [
    {
      title: t('userManagement.stt'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: 'Full name',
      dataIndex: 'full_name',
      key: 'full_name',
      editable: true,
      sorter: (a: any, b: any) => a.full_name.length - b.full_name.length,
    },
    {
      title: t('userManagement.username'),
      dataIndex: 'user_name',
      key: 'user_name',
      // with: '30%',
      sorter: (a: any, b: any) => a.user_name.length - b.user_name.length,
    },
    {
      title: t('userManagement.action'),
      dataIndex: 'delete',
      width: MobileView ? '45%' : 100,
      render: (value: any, record: { key: any }) => {
        const editable = isEditing(record);
        return (
          <div>
            {editable ? (
              <span className="td-common-action">
                <Button
                  type="primary"
                  ghost
                  className="td-common-action-item"
                  onClick={() => save(record, 'edit')}
                  style={{
                    marginRight: 8,
                  }}
                >
                  Save
                </Button>
                <Button
                  className="td-common-action-item"
                  onClick={() => setEditingKey('')}
                  style={{
                    marginRight: 8,
                  }}
                >
                  Cancel
                </Button>
              </span>
            ) : (
              <Button
                className="td-common-action-item"
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
              >
                Edit
              </Button>
            )}

            <Popconfirm
              placement="topRight"
              title={'Delete User'}
              onConfirm={() => handleDeleteStaff(record)}
              okText="Yes"
              cancelText="No"
            >
              {isDesktop ? (
                <Button danger>{t('userManagement.delete')}</Button>
              ) : (
                <DeleteOutlined style={{ color: 'red', fontSize: 25 }} />
              )}
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const getListStaffOfAdminId = () => {
    promotionClient
      .post('/api/v1/manager/staff_user/list', {
        admin_id: user,
      })
      .then((res) => {
        convertArr(res.data.staff_list);
      });
  };

  const convertArr = (data: any) => {
    const arr = data.map((item: any, index: number) => {
      return {
        key: index,
        ...item,
      };
    });
    setDataSoucre(arr);
  };

  const AddStaff = (values: any) => {
    promotionClient
      .post('/api/v1/manager/staff_user/create', {
        admin_id: user,
        user_name: values.username,
        full_name: values.fullName,
        password: values.password,
        token: '',
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Create Staff successfully',
          });
          getListStaffOfAdminId();
          onReset();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const handleDeleteStaff = (staff: any) => {
    promotionClient
      .post('/api/v1/manager/staff_user/delete', {
        staff_id: staff.id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Delete user successfully',
          });
          getListStaffOfAdminId();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const EditableCell = (props: any) => {
    const inputNode =
      props.inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...props}>
        {props.editing ? (
          <Form.Item
            name={props.dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${props.title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          props.children
        )}
      </td>
    );
  };

  return (
    <div>
      <Row gutter={15}>
        <Col className="gutter-row" lg={6} md={24} xs={24}>
          <div className="list-staff-item">
            <Collapse
              style={{ border: '2px solid green', maxWidth: '500px' }}
              defaultActiveKey={'0'}
              accordion
              expandIcon={({ isActive }) => (
                <PlusCircleOutlined
                  style={{ fontSize: '120%', color: 'green' }}
                  rotate={isActive ? 130 : 0}
                />
              )}
            >
              <Panel header={t('userManagement.createUser')} key="1">
                <Form
                  {...layout}
                  form={form}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={AddStaff}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label={t('userManagement.username')}
                    name="username"
                    className="staff-item"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your username!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label={t('Register.fullname')}
                    name="fullName"
                    className="staff-item"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your fullName!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label={t('Register.password')}
                    name="password"
                    className="staff-item"
                    rules={[
                      // { required: true, message: 'Please input your password!', },
                      {
                        min: 4,
                        message: 'Please enter at least 4 characters',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label={t('Register.rePasword')}
                    name="confirmPassword"
                    className="staff-item"
                    rules={[
                      // { required: true, message: 'Please confirm your password!' },
                      {
                        min: 4,
                        message: 'Please enter at least 4 characters',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'The two passwords that you entered do not match!'
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item className="staff-item" {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                      {t('userManagement.btnCreate')}
                    </Button>
                  </Form.Item>
                </Form>
              </Panel>
            </Collapse>
          </div>
        </Col>
        <Col className="gutter-row" lg={24} md={24} xs={24}>
          <div className="table-staff">
            <Form form={formEdit} className={'mt-3'} component={false}>
              <Table
                bordered
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                pagination={{ position: ['bottomRight'] }}
                rowClassName="editable-row"
                scroll={{ x: 600 }}
                dataSource={dataSource}
                columns={mergedColumns}
              />
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserManagement;
