import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, notification, Pagination, Row, Table, Tabs } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import './Rule.scss';

const RuleList = () => {
  const history = useHistory();
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    order: 'asc',
    page: 1,
    page_size: 10,
  });

  const { TabPane } = Tabs;

  useEffect(() => {
    getRuleList();
  }, []);

  const handleTableChange = (page: number, pageSize?: number) => {
    setPagination({
      ...pagination,
      page: page,
      page_size: pageSize || pagination.page_size,
    });
  };

  const columns = [
    {
      title: t('rule.list.no'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: t('rule.list.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('rule.list.rules'),
      dataIndex: 'list',
      key: 'list',
      render: (item: any, value: any) => item.toString(),
    },
    {
      title: t('rule.list.version'),
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: t('rule.list.action'),
      dataIndex: '',
      key: 'delete',
      render: (item: any, value: any) => (
        <div className="ant-btn-group" style={{ marginLeft: '5px' }}>
          <Button
            icon={<SearchOutlined />}
            onClick={() => history.push(`${PATH.RULE.LIST}/${value.id}`)}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => history.push(`${PATH.RULE.LIST}/${value.id}/edit`)}
          />
        </div>
      ),
    },
  ];

  // const operations = (
  //   <>
  //     <AddButton onClick={() => history.push(PATH.BANK.CREATE)}>
  //       Create
  //     </AddButton>
  //   </>
  // );

  const getRuleList = () => {
    setLoading(true);
    promotionClient
      .get('/api/v1/manager/app_rule/blocklist/list')
      .then((res: any) => {
        if (res.data.total) {
          setRules(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={t('rule.list.title')} key="1">
        <Row gutter={15}>
          <Col className="gutter-row" lg={24} md={24} xs={24}>
            <div className="table-staff">
              <Table
                rowKey={(record) => record.id}
                bordered
                pagination={false}
                scroll={{ x: 600 }}
                dataSource={rules}
                columns={columns}
                loading={loading}
              />
            </div>
            <div className="user-pagination">
              <Pagination
                defaultCurrent={pagination.page}
                pageSize={pagination.page_size}
                total={500}
                onChange={handleTableChange}
              />
            </div>
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  );
};

export default RuleList;
