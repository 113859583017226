import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Dropdown, Layout, Menu } from 'antd';
import {
  BellOutlined,
  DownOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  HomeOutlined,
  UserOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { StoreContext } from 'contexts';
import { logout } from 'pkg/user/repo';
import { changeLanguage, getCurrentLanguage } from 'pkg/localization/helpers';
import { REGIONS } from 'pkg/localization/constants';
import { IRegionItem } from 'pkg/localization/types';
import { t } from 'i18n';
import { getCookie } from '../../../../pkg/cookie/helpers';
import { useHistory } from 'react-router-dom';
import { promotionClient } from 'pkg/request/clients';
import IconSupport from 'assets/images/icon-support.png';
import IconSale from 'assets/images/icon-sales.png';
import IconManager from 'assets/images/icon-manager.png';

const { Header } = Layout;

const AppHeader: React.FC = () => {
  const history = useHistory();
  const [haveAgent, setHaveAgent] = useState([]);
  useEffect(() => {
    // getListAgent()
  }, []);
  const localizationMenu = (
    <Menu className="hoho">
      {Object.values(REGIONS).map((el: IRegionItem) => (
        <Menu.Item key={el.key} onClick={() => changeLanguage(el.key)}>
          <Avatar src={el.flag} shape="square" />
          <span style={{ marginLeft: 10 }}>{el.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );
  const routingInformationPage = () => {
    history.push('/information');
  };

  const userMenu = (
    <Menu>
      <Menu.Item data-testid="information" onClick={routingInformationPage}>
        <UserOutlined />
        <span>{t('user.Information')}</span>
      </Menu.Item>
      <Menu.Item data-testid="logout-btn" onClick={logout}>
        <LogoutOutlined />
        <span>{t('Logout')}</span>
      </Menu.Item>
    </Menu>
  );

  const getAuthor = () => {
    return getCookie('author_user');
  };

  const currentRegion = REGIONS[getCurrentLanguage()];

  const getNameAdmin = () => {
    return getCookie('current_username') || 'admin';
  };

  return (
    <Header className="app-header">
      <HomeOutlined
        onClick={() => history.push('/Dashboard')}
        className="app-icon"
      />
      {/* {haveAgent.length > 0 ? (
        <a
          style={{ color: '#000000' }}
          href={`https://safeweb.app/${currentRegion.key}/download/`}
          target={'_blank'}
        >
          <DownloadOutlined style={{ color: '#000000' }} className="app-icon" />
        </a>
      ) : (
        <a
          style={{ color: 'transparent' }}
          href={`https://safeweb.app/${currentRegion.key}/download/`}
          target={'_blank'}
        >
          <DownloadOutlined style={{ color: 'red' }} className="app-icon" />
        </a>
      )}
      <a
        href={`https://safeweb.app/${currentRegion.key}/blog/how-to-install-safeweb-app/`}
      >
        <QuestionCircleOutlined className="app-icon" />
      </a> */}

      <span style={{ position: 'relative' }} className="position-relative">
        <BellOutlined className="app-icon" />
        <span className={'notification-dot'}></span>
      </span>

      <Dropdown overlay={localizationMenu} trigger={['click']}>
        <span className="app-user">
          <Avatar src={currentRegion && currentRegion.flag} shape="square" />
          <span className="name">{currentRegion && currentRegion.name}</span>
        </span>
      </Dropdown>

      <StoreContext.Consumer>
        {(context) => (
          <Dropdown overlay={userMenu} trigger={['click']}>
            <span className="app-user">
              {/* <UserOutlined
                style={{
                  color: getAuthor() === 'standard' ? 'blue' : 'green',
                  fontSize: '120%',
                  fontWeight: 800,
                }}
              /> */}
              <img
                style={{ width: '30px' }}
                src={
                  getAuthor() === 'salesman'
                    ? IconSale
                    : getAuthor() === 'manager'
                    ? IconManager
                    : IconSupport
                }
                alt="logo"
              />
              <span className="name">{getNameAdmin()}</span>
              <DownOutlined />
            </span>
          </Dropdown>
        )}
      </StoreContext.Consumer>
    </Header>
  );
};

export default AppHeader;
function getAdminId() {
  throw new Error('Function not implemented.');
}
