import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
} from 'antd';
import { t } from 'i18n';
import { BackButton, SaveButton } from 'modules/base/Buttons';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
const tabList = [
  {
    key: 'info',
    tab: t('browser.edit.title'),
  },
];
const tailLayout = {
  wrapperCol: { span: 17 },
};
const { Option } = Select;

type Params = {
  id: string;
};

const EditPromotion = () => {
  const router = useRouter<Params>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [browsers, setBrowsers] = useState<any>(null);

  const getDetailBrowser = (id: string) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/black_browser/get`, { id })
      .then((res: any) => {
        if (res.data) {
          setBrowsers(res.data.item);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getDetailBrowser(id);
    }
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(browsers);
  }, [browsers]);

  const onFinishFailed = (val: any) => {};
  const onSubmit = (val: any) => {
    const data = {
      ...val,
      id,
    };
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/black_browser/edit`, data)
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Update browser success',
          });
          history.push(`${PATH.BROWSER.LIST}/${id}`);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handDeleteBrowser = (id: string) => {
    promotionClient
      .post(`/api/v1/manager/black_browser/delete`, { id })
      .then((res: any) => {
        if (res.data) {
          notification.success({
            message: t('Success'),
            description: 'Delete block browser successfully',
          });
          history.push(PATH.BROWSER.LIST);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Form
        layout="vertical"
        name="updateUser"
        initialValues={browsers}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          label={t('browser.list.browserName')}
          name="browser_name"
          rules={[{ required: true, message: 'Please enter browser name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('browser.list.fileName')}
          name="file_name"
          rules={[
            { required: true, message: 'Please enter file browser name!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('browser.list.originalName')} name="original_name">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('browser.list.fileDescription')}
          name="file_description"
        >
          <Input />
        </Form.Item>

        <Form.Item className="staff-item" {...tailLayout}>
          <BackButton />
          <SaveButton htmlType="submit" />
        </Form.Item>
      </Form>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {browsers && (
            <>
              <InfoWithAvatar id={id} data={[]} loading={loading} />
            </>
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
            tabBarExtraContent={
              <Popconfirm
                title="Are you sure to delete this browser?"
                onConfirm={() => handDeleteBrowser(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger>
                  Delete
                </Button>
              </Popconfirm>
            }
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditPromotion;
