import { Button, Form, Input, InputNumber, Select } from 'antd';
import { t } from 'i18n';
import React from 'react';

interface Props {
  staff: any;
  handleSubmit: (item: any, data: any) => void;
  handleCancel: () => void;
}
const { Option } = Select;

const ChangeRoleUser = ({ staff, handleSubmit, handleCancel }: Props) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ type: 'standard', total: 1 }}
        onFinish={(value) => handleSubmit(staff, value)}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t('users.changeRole.form.type')}
          name="type"
          className="staff-item"
          rules={[{ required: true, message: 'Please input your type!' }]}
        >
          <Select placeholder="Select a option and change input text above">
            <Option value="standard">Standard</Option>
            <Option value="option">Premium</Option>
          </Select>
        </Form.Item>
        <Form.Item
          className="staff-item"
          name="total"
          label={t('users.changeRole.form.totalMonth')}
          rules={[{ required: true, message: 'Please input your total!' }]}
        >
          <InputNumber min={1} step={1} />
        </Form.Item>
        <Form.Item className="staff-item" {...tailLayout}>
          <Button
            className="btn-form-change-role"
            htmlType="button"
            onClick={handleCancel}
          >
            {t('users.changeRole.button.cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('users.changeRole.button.submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangeRoleUser;
