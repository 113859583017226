import { Col, Progress, Row, Spin } from 'antd';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
// import './../AgentPage.scss';
import { handleToDay } from './action';
import ButtonSelectDay from './ButtonSelectDay';
import ChartUrlContent from './ChartUrlContent';
type Props = {
  getAdminId: () => void;
  getTimeZone: () => void;
  agentId: string;
};
const ChartUrl = ({ getAdminId, getTimeZone, agentId }: Props) => {
  const [dataSourceChart, setDataSourceChart] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [max, setMax] = useState<number>(0);

  useEffect(() => {
    getApiChart(0);
  }, []);

  const getApiChart = (number: number) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/url_request/chart`, {
        admin_id: getAdminId(),
        agent_id: agentId,
        sort: 'desc',
        date: handleToDay(number),
        top: 20,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        if (+res.data.total > 0) {
          setDataSourceChart(res.data.urls);
          const max = res.data.urls.reduce((prev: any, current: any) =>
            +prev.request_count > +current.request_count ? prev : current
          );
          setMax(+max.request_count);
        } else {
          setDataSourceChart([]);
          setMax(0);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const result = dataSourceChart.map((item: any) => {
      item.request_count = +item.request_count;
      item.domain = item.domain;
      item.percent = (+item.request_count / max) * 100;
      return item;
    });
    setDataSourceChart(result);
  }, [max]);

  const navigateUrl = (url: string) => {
    let element = document.createElement('a');

    if (url.startsWith('http://') || url.startsWith('https://')) {
      element.href = url;
    } else {
      element.href = 'https://' + url;
    }

    element.setAttribute('target', '_blank');

    element.click();
  };

  console.log('max', max);
  console.log('dataSourceChart', dataSourceChart);

  return (
    <Spin spinning={loading} tip="Loading...">
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <ButtonSelectDay actionFunction={getApiChart} />
        </Col>
      </Row>

      <div style={{ padding: 30 }}>
        {!!dataSourceChart.length && !loading ? (
          dataSourceChart.map((item: any) => {
            return (
              <div style={{ marginBottom: 10 }} key={item.domain}>
                <span>
                  <a onClick={() => navigateUrl(item.domain)} target="_blank">
                    {item.domain}
                  </a>
                </span>
                <Progress
                  percent={item.percent}
                  status="active"
                  format={(percent) => `${item.request_count} click`}
                  strokeWidth={12}
                />
              </div>
            );
          })
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            No data
          </div>
        )}
      </div>
    </Spin>
  );
};

export default ChartUrl;
