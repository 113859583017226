import {
  CloseCircleOutlined,
  EditOutlined,
  HistoryOutlined,
  LockOutlined,
  PoweroffOutlined,
  RedoOutlined,
  UnlockOutlined,
  WarningFilled,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Form,
  Input,
  Modal,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Switch,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from '../../../../i18n';
import { promotionClient } from '../../../../pkg/request/clients';
import './Information.scss';
type Props = {
  user: any;
  tab: string;
};

export default function Setting({ user, tab }: Props) {
  const [agents, setAgents] = useState<any>([]);
  const [version, setVersions] = useState<string>('');
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const agentId = useRef(null);

  const history = useHistory();
  useEffect(() => {
    if (user && tab === '5') {
      getList();
      getLastVersion();
    }
  }, [user, tab]);

  const getList = () => {
    promotionClient
      .post(`/api/v1/manager/agent_access/list`, {
        admin_id: user,
      })
      .then((res: any) => {
        if (res.data.total) {
          setAgents(res.data.list);
        } else {
          setAgents([]);
        }
      });
  };
  const getLastVersion = () => {
    promotionClient
      .get(`/api/v1/manager/latest_version/get_win`)
      .then((res: any) => {
        if (res.data.success) {
          setVersions(res.data.version);
        } else {
          setVersions('');
        }
      });
  };

  const updateVersion = (agent_id: string) => {
    promotionClient
      .post(`/api/v1/manager/agent_command/send`, {
        agent_id,
        admin_id: user,
        command: 'update_now',
      })
      .then((res: any) => {
        if (res.data.success) {
          getList();
          notification.success({
            message: t('Success'),
            description: 'Update successfully',
          });
        } else {
          notification.error({
            message: t('Error'),
            description: 'Update  fail',
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err.message,
        });
      });
  };

  const setBlockUser = (id: string, type = 'blockUser') => {
    let enPoint = '/api/v1/manager/agent_access/block_browser/set';
    if (type === 'setUserLoginStartup') {
      enPoint = '/api/v1/manager/agent_access/user_login/set';
    }
    promotionClient
      .post(enPoint, {
        agent_id: id,
      })
      .then((res: any) => {
        if (res.data.success) {
          getList();
          notification.success({
            message: t('Success'),
            description: 'Update successfully',
          });
        } else {
          notification.error({
            message: t('Error'),
            description: 'Update  fail',
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err.message,
        });
      });
  };
  const handleSubmitCommand = (agent_id: any, command: string) => {
    promotionClient
      .post(`/api/v1/manager/agent_command/send`, {
        admin_id: user,
        agent_id,
        command,
      })
      .then((res: any) => {
        notification.success({
          message: t('Success'),
          description: 'Sent Command line successfully',
        });
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const handleDisableAgent = (agent_id: string) => {
    promotionClient
      .post(`/api/v1/manager/agent_access/logout`, {
        admin_id: user,
        agent_id,
      })
      .then((res: any) => {
        getList();
        getLastVersion();
        notification.success({
          message: t('Success'),
          description: 'Disable agent successfully',
        });
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const { Panel } = Collapse;
  const [form] = useForm();
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 18 },
  };

  const onFinishConnect = (values: any) => {
    setBtnLoading(true);
    promotionClient
      .post(`/api/v1/manager/agent_access/login`, {
        agent_id: values.agent_id,
        otp: values.OTP,
        admin_id: user,
      })
      .then((res: any) => {
        const { success } = res.data;
        if (success) {
          checkAccept(values);
        } else {
          handleAlert(res.data.status, 'accounts with are maximum of 4 agents');
        }
      })
      .catch((err) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const handleAlert = (message: string, description: string) => {
    return notification.error({
      message,
      description,
    });
  };

  const checkAccept = (values: any) => {
    setBtnLoading(true);
    let accepted = false;
    const loopInterval = setInterval(function () {
      promotionClient
        .post(`/api/v1/manager/agent_access/check`, {
          agent_id: values.agent_id,
          admin_id: user,
        })
        .then((res: any) => {
          accepted = res.data.success;
          if (accepted) {
            setBtnLoading(false);
            clearInterval(loopInterval);
            getList();
            getLastVersion();
            form.resetFields();
          }
        })
        .catch((err) => {
          notification.error({
            message: t('Error'),
            description: err,
          });
        });
    }, 2000);

    setTimeout(() => {
      if (!accepted) {
        clearInterval(loopInterval);
        form.resetFields();
        setBtnLoading(false);
        notification.error({
          message: t('Error'),
          description: 'Timeout has expired, please re-enter a new OTP',
        });
      }
    }, 7000);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const SubmitAgentName = (value: any) => {
    promotionClient
      .post(`/api/v1/manager/agent/set_name`, {
        name: value.agent_name,
        agent_id: agentId.current,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('children.setting.updateAgentSuccess'),
          });
          getList();
          setIsModalVisible(false);
        } else {
          notification.error({
            message: t('Error'),
            description: t('children.setting.updateAgentFail'),
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  return (
    <div className="site-card-border-less-wrapper">
      <Row>
        <Col span={8} offset={8}>
          <Collapse defaultActiveKey={'0'}>
            <Panel header={t('children.setting.connectAgent')} key="1">
              <Form
                form={form}
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinishConnect}
              >
                <Form.Item
                  className="mt-1"
                  label="ID"
                  name="agent_id"
                  rules={[{ required: true, message: t('required.AgentId') }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className="mt-1"
                  label="OTP"
                  name="OTP"
                  rules={[
                    { required: true, message: t('required.importOtp') },
                    {
                      required: true,
                      message: t('required.notSpace'),
                      pattern: new RegExp(/^[a-zA-Z0-9.\-_$@*!]{1,30}$/),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item {...tailLayout} className="mt-1">
                  <Button loading={btnLoading} type="primary" htmlType="submit">
                    {t('children.setting.connectAgent')}
                  </Button>
                </Form.Item>
              </Form>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row>
        {agents.map((item: any, index: number) => {
          return (
            <Col
              key={index}
              sm={24}
              md={8}
              className="information-page-bg ghost"
            >
              <PageHeader
                ghost={false}
                // onBack={() => window.history.back()}
                title={t('children.setting.agentId') + ': ' + item.agent_id}
                extra={
                  <Popconfirm
                    title="Are you sure"
                    onConfirm={() => handleDisableAgent(item.agent_id)}
                  >
                    <Button
                      icon={<PoweroffOutlined />}
                      type="primary"
                      style={{
                        backgroundColor: '#eee',
                        color: 'black',
                        border: 'none',
                      }}
                    >
                      Disconnect Agent
                    </Button>
                  </Popconfirm>
                }
              >
                <Descriptions size="small" column={1}>
                  {/* <Descriptions.Item label={t('children.setting.agentId')}>
                    {item.agent_id}
                  </Descriptions.Item> */}
                  <Descriptions.Item label={t('children.setting.name')}>
                    {item.name}
                    <EditOutlined
                      onClick={() => {
                        showModal();
                        agentId.current = item.agent_id;
                      }}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label={t('children.setting.userId')}>
                    {item.user_id}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={t('children.setting.userLoginStartup')}
                  >
                    <Switch
                      onChange={() =>
                        setBlockUser(item.agent_id, 'setUserLoginStartup')
                      }
                      defaultChecked={item.user_login}
                      checked={item.user_login}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={t('children.setting.blockUnsupportedBrowser')}
                  >
                    <Switch
                      onChange={() => setBlockUser(item.agent_id)}
                      defaultChecked={item.block_browser}
                      checked={item.block_browser}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t('children.setting.osAdmin')}>
                    {item.os_admin ? (
                      <WarningFilled
                        style={{ fontSize: '18px', color: '#ffcc00' }}
                      />
                    ) : (
                      <CloseCircleOutlined style={{ fontSize: '18px' }} />
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('children.setting.version')}>
                    {item.version}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('children.setting.lastVersion')}>
                    <span className={'mr-1'}>{version}</span>
                    {item.version !== version && (
                      <Button
                        onClick={() => updateVersion(item.agent_id)}
                        style={{ background: 'green' }}
                        type="primary"
                        shape="round"
                        size={'small'}
                      >
                        {t('children.setting.update')}
                      </Button>
                    )}
                  </Descriptions.Item>
                </Descriptions>
                <div className="list-command-action">
                  <Button
                    icon={<RedoOutlined />}
                    className={'mr-1 mt-1 mb-1'}
                    onClick={() =>
                      handleSubmitCommand(item.agent_id, 'restart_now')
                    }
                    type="primary"
                    shape="round"
                    size={'small'}
                  >
                    Restart
                  </Button>
                  <Button
                    icon={<LockOutlined />}
                    className={'mr-1 mt-1 mb-1'}
                    onClick={() =>
                      handleSubmitCommand(item.agent_id, 'lockscreen')
                    }
                    danger
                    type="primary"
                    shape="round"
                    size={'small'}
                  >
                    Lock screen
                  </Button>
                  <Button
                    icon={<UnlockOutlined />}
                    style={{ background: 'green' }}
                    className={'mr-1 mt-1 mb-1'}
                    onClick={() =>
                      handleSubmitCommand(item.agent_id, 'unlockscreen')
                    }
                    type="primary"
                    shape="round"
                    size={'small'}
                  >
                    Unlock screen
                  </Button>
                  <Button
                    icon={<HistoryOutlined />}
                    style={{ background: 'orange' }}
                    className={'mr-1 mt-1 mb-1'}
                    onClick={() =>
                      history.push(`agentpage/${item.agent_id}/${user}/tabs/2`)
                    }
                    type="primary"
                    shape="round"
                    size={'small'}
                  >
                    History
                  </Button>
                </div>
              </PageHeader>
            </Col>
          );
        })}
      </Row>
      <Modal
        title={t('children.setting.updateAgent')}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={(value: any) => SubmitAgentName(value)}
        >
          <Form.Item
            label={t('children.setting.agentName')}
            name="agent_name"
            rules={[
              {
                required: true,
                message: t('children.setting.validateAgentName'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
            <Button type="primary" className="mt-1" htmlType="submit">
              {t('children.setting.btnUpdate')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
