import { Form, Input, InputNumber, notification, Switch, Table } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import ApplyRule from '../../components/ApplyRule';

type Props = {
  adminId: string;
  type: string;
  tab: string;
  dataApply: any[];
  dataRule: any[];
  getBlacklistApiOfStaff: () => void;
};
const ListBlackList = (props: Props) => {
  const { getBlacklistApiOfStaff } = props;
  const [loadingStaffActive, setLoadingStaffActive] = useState(false);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: { key: any }) => record.key === editingKey;

  const EditableCell = (props: any) => {
    const inputNode =
      props.inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td>
        {props.editing ? (
          <Form.Item
            name={props.dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${props.title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          props.children
        )}
      </td>
    );
  };
  const handleOptionChange = (
    status: any,
    value: any,
    staff: any,
    index: number
  ) => {
    let vType = 'normal';
    let api = '/api/v1/manager/staff_web/normal/set';

    switch (status) {
      case 'blacklist':
        vType = 'blacklist';
        api = '/api/v1/manager/staff_web/blacklist/set';
        break;

      case 'whitelist':
        vType = 'whitelist';
        api = '/api/v1/manager/staff_web/whitelist/set';
        break;

      case 'blocklist':
        vType = 'blocklist';
        api = '/api/v1/manager/staff_app/blocklist/set';
        break;

      default:
        vType = 'normal';
        break;
    }

    if (status === 'normal' && props.type === 'blocklist') {
      api = '/api/v1/manager/staff_app/normal/set';
    }

    promotionClient
      .post(api, {
        staff_id: staff.staff_id,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: `Update Tabmode ${vType} successfully`,
          });
          getBlacklistApiOfStaff();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const handleActiveStaff = (value: any, myStaff: any) => {
    let api = '';
    let mode = '';
    if (props.type === 'blocklist') {
      api = '/api/v1/manager/staff_app/notification_mode/set';
      mode = myStaff.notification_mode === 'hide' ? 'toast' : 'hide';
      if (myStaff.notification_mode === 'toast') {
        setLoadingStaffActive(true);
      } else {
        setLoadingStaffActive(false);
      }
    } else {
      api = '/api/v1/manager/staff_web/tab_mode/set';
      mode = myStaff.tab_action === 'cover' ? 'closetab' : 'cover';
      if (myStaff.tab_action === 'cover') {
        setLoadingStaffActive(true);
      } else {
        setLoadingStaffActive(false);
      }
    }

    promotionClient
      .post(api, {
        staff_id: myStaff.staff_id,
        mode,
      })
      .then((res: any) => {
        if (res.data.success) {
          getBlacklistApiOfStaff();
          notification.success({
            message: t('Success'),
            description: loadingStaffActive
              ? 'Active successfully'
              : 'Inactive successfully',
          });
        } else {
          notification.error({
            message: t('Success'),
            description: 'Active Staff Fail',
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const handApplyRule = (rule_id: any, staff_id: any) => {
    let api = '/api/v1/manager/staff_web/blacklist/add';
    if (props.type === 'whitelist') {
      api = '/api/v1/manager/staff_web/whitelist/add';
    } else if (props.type === 'blocklist') {
      api = '/api/v1/manager/staff_app/blocklist/add';
    }
    promotionClient
      .post(api, {
        staff_id,
        rule_id,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: `Update successfully`,
          });
          getBlacklistApiOfStaff();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const columnsApply = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: 'Name',
      width: '10%',
      editable: true,
      render: (value: any, staff: any) =>
        staff.full_name ? staff.full_name : staff.user_name,
    },
    {
      title:
        props.type === 'blacklist'
          ? 'Blacklist'
          : props.type === 'blocklist'
          ? 'Blocklist name'
          : 'Whitelist',
      // dataIndex:
      //   props.type === 'blacklist'
      //     ? 'blacklist_name'
      //     : props.type === 'blocklist'
      //     ? 'blocklist_name'
      //     : 'whitelist_name',
      editable: true,
      render: (value: any, staff: any) => {
        const handleChange = (rule_id: any) => {
          handApplyRule(rule_id, staff.staff_id);
        };
        const init =
          props.type === 'blacklist'
            ? staff.blacklist_id
            : props.type === 'blocklist'
            ? staff.blocklist_id
            : staff.whitelist_id;
        return (
          <ApplyRule
            options={props.dataRule}
            initValue={init}
            onChange={handleChange}
          />
        );
      },
    },
    {
      title: 'Run mode',
      dataIndex: 'enable',
      key: 'enable',
      render: (value: any, staff: any, index: number) => {
        return (
          // dung onchange
          <form>
            <div className="radio-group">
              <label>
                <input
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                  }}
                  onChange={() =>
                    handleOptionChange('normal', value, staff, index)
                  }
                  type="radio"
                  id="default"
                  name="normal"
                  checked={staff.run_mode === 'normal'}
                />
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  Normal
                </span>
              </label>
              <label>
                <input
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                  }}
                  onChange={() =>
                    handleOptionChange(
                      props.type === 'blacklist'
                        ? 'blacklist'
                        : props.type === 'blocklist'
                        ? 'blocklist'
                        : 'whitelist',
                      value,
                      staff,
                      index
                    )
                  }
                  type="radio"
                  id="blacklist"
                  name="blacklist"
                  checked={
                    staff.run_mode ===
                    (props.type === 'blacklist'
                      ? 'blacklist'
                      : props.type === 'blocklist'
                      ? 'blocklist'
                      : 'whitelist')
                  }
                />
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {props.type === 'blacklist'
                    ? 'Block Web'
                    : props.type === 'blocklist'
                    ? 'Block App'
                    : 'Allow Web'}
                </span>
              </label>
            </div>
          </form>
        );
      },
    },
    {
      title: 'Tab Mode',
      key: 'active',
      render: (value: any, staff: any) => (
        <Switch
          onChange={() => handleActiveStaff(value, staff)}
          defaultChecked={
            props.type === 'blocklist'
              ? staff.notification_mode === 'hide'
              : staff.tab_action === 'closetab'
          }
        />
      ),
    },
  ];
  const mergedColumns = columnsApply.map((col, index) => {
    if (!col.editable) {
      return {
        index: index,
        ...col,
      };
    }

    return {
      index: index,
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const cancel = () => {
    setEditingKey('');
  };

  return (
    <div>
      <Table
        rowKey={(record) => record.staff_id}
        scroll={{ x: 600 }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={props.dataApply}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </div>
  );
};

export default ListBlackList;
