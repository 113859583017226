import { getTimezoneProps, SelectTimezone } from '@capaj/react-select-timezone';
import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import { t } from 'i18n';
import { BackButton, CancelButton, SaveButton } from 'modules/base/Buttons';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import LicenseStatus from 'modules/base/LicenseStatus';
import { KEY_CURRENT_USER_ID } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
const tabList = [
  {
    key: 'info',
    tab: 'User detail',
  },
];

type Params = {
  id: string;
};

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const tailLayout = {
  wrapperCol: { offset: 7, span: 17 },
};

const EditUser = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const router = useRouter<Params>();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [timezone, setTimezone] = useState<string>('');
  const currentUserId = useRef(getCookie(KEY_CURRENT_USER_ID));

  const getUser = (id: any) => {
    promotionClient
      .get(`/api/v1/manager/user/${id}`)
      .then((res: any) => {
        if (res.data) {
          setUser(res.data);
          setTimezone(res.data.time_zone.split(' ')[1]);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    if (id) getUser(id);
  }, [id]);

  const reloadUser = () => {
    if (id) getUser(id);
  };
  let country = '';
  const selectCountry = (val: string) => {
    country = val;
  };

  const handleLoaded = (val: any) => {
    getCookie('current_username');
    const data = {
      ...val,
      time_zone: timezone,
      updated_by: currentUserId.current,
      id,
    };
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/user/update`, data)
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Update user success',
          });
          history.push(`${PATH.USER.PAGE}/${id}`);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const onFinishFailed = (val: any) => {};

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <div>
        <Form
          {...layout}
          name="updateUser"
          initialValues={user}
          onFinish={handleLoaded}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            label={t('users.detail.form.email')}
            name="email"
            rules={[
              { required: true, message: 'Xin hãy nhập Email!' },
              {
                type: 'email',
                message: 'Xin hãy nhập đúng định dạng Email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('users.detail.form.phone')} name="phone">
            <Input />
          </Form.Item>
          <Form.Item label={t('users.detail.form.fullName')} name="full_name">
            <Input />
          </Form.Item>

          <Form.Item label={t('Register.country')} name="country">
            <CountryDropdown
              value={country}
              onChange={(val) => selectCountry(val)}
            />
          </Form.Item>
          <Form.Item label={t('Register.timezone')}>
            <SelectTimezone
              value={timezone}
              isClearable
              guess
              onChange={(val: any) => {
                let timezoneProps = getTimezoneProps(val);
                setTimezone(timezoneProps.label);
              }}
            />
          </Form.Item>
          <Form.Item className="staff-item" {...tailLayout}>
            <BackButton />
            <SaveButton htmlType="submit" />
          </Form.Item>
        </Form>
      </div>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {user && (
            <InfoWithAvatar
              data={[
                { label: 'users.detail.superRole', value: user.super_role },
                { label: 'users.detail.adminRole', value: user.admin_role },
              ]}
              loading={loading}
              setLoading={reloadUser}
              isUser={true}
              user={user}
            />
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
          {user && (
            <LicenseStatus
              loading={loading}
              license={{
                title: `${t('license.title')}: ${user.license}`,
                begin: user.subscription_begin,
                end: user.subscription_end,
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EditUser;
