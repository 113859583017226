import { DEFAULT_PAGINATION } from 'pkg/pagination/constants';
import { useLocation, useParams } from 'react-router-dom';
import {
  getObjFromQueryString,
  getQueryStringFromObj,
} from 'pkg/object/helpers';
import { browserHistory } from './helper';

export const useRouter = <MatchParams>(
  defaultParams: object = DEFAULT_PAGINATION
) => {
  const { search } = useLocation();
  const matchParams = useParams<MatchParams>();

  const push = (params: object, newRoot?: string) => {
    browserHistory.push({
      ...(newRoot && { pathname: newRoot }),
      search: getQueryStringFromObj(params),
    });
  };
  return {
    matchParams,
    params: search ? getObjFromQueryString(search) : defaultParams,
    push,
  };
};
