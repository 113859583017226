import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { promotionClient } from 'pkg/request/clients';
import axios from 'axios';
import {
  KEY_CURRENT_USERNAME,
  KEY_ACCESS_TOKEN,
  KEY_REFRESH_TOKEN,
  KEY_TIMEZONE,
  AUTHOR_USER,
  KEY_CURRENT_USER_ID,
} from 'pkg/cookie/constants';
import { setCookie } from 'pkg/cookie/helpers';

const Sso = () => {
  const { search } = useLocation();

  const values = queryString.parse(search);
  console.log('values :>> ', values.token);
  useEffect(() => {
    login();
  }, []);

  const login = () => {
    axios
      .get('/api/v1/manager/auth/sso_login', {
        headers: {
          Authorization: `${values.token}`,
          ContentType: 'application/json',
        },
        baseURL: `${process.env.REACT_APP_END_POINT_API}`,
      })
      .then((rs) => {
        if (rs.status === 200) {
          const res = rs.data;
          const accessToken = res.access_token;
          const refreshToken = res.fresh_token;
          const timezone = res.timezone;
          setCookie(KEY_CURRENT_USERNAME, res.user_name, 1);
          setCookie(KEY_ACCESS_TOKEN, accessToken);
          setCookie(KEY_REFRESH_TOKEN, refreshToken);
          setCookie(KEY_TIMEZONE, timezone);
          setCookie(AUTHOR_USER, res.admin, 1);
          // const userInfo = await getFullUserInfo();
          setCookie(KEY_CURRENT_USER_ID, res.id, 1);
        }
      })
      .catch((err) => {
        console.log('err :>> ', err);
      })
      .finally(() => {
        window.location.href = '/';
      });
  };

  return <div>Login...</div>;
};

export default Sso;
