import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
} from 'antd';
import { t } from 'i18n';
import { BackButton, CancelButton, SaveButton } from 'modules/base/Buttons';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import LicenseStatus from 'modules/base/LicenseStatus';
import PriceInput from 'modules/base/PriceInput';
import moment from 'moment';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { formatter } from './Promotions';
const tabList = [
  {
    key: 'info',
    tab: t('promotion.edit.title'),
  },
];
const tailLayout = {
  wrapperCol: { span: 17 },
};
const { Option } = Select;

type Params = {
  id: string;
  code: string;
};

const EditPromotion = () => {
  const router = useRouter<Params>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { id, code } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [promotion, setPromotion] = useState<any>(null);
  const [countPromotion, setCountPromotion] = useState<any>(null);

  const getDetailPromotion = (code: string) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/promotion/get`, { code })
      .then((res: any) => {
        if (res.data) {
          setPromotion(res.data);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getCountPromotion = (code: string) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/promotion/count`, { code })
      .then((res: any) => {
        if (res.data) {
          setCountPromotion(res.data);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (code) {
      getDetailPromotion(code);
      getCountPromotion(code);
    }
  }, [id, code]);

  useEffect(() => {
    form.setFieldsValue({
      ...promotion,
      ...{
        expire: promotion ? moment(promotion.expire) : undefined,
      },
    });
  }, [promotion]);

  const onFinishFailed = (val: any) => {};
  const onSubmit = (val: any) => {
    const data = {
      ...val,
      expire: val.expire.format('YYYY-MM-DD'),
      money: val.money.toString(),
      code: promotion.code,
    };
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/promotion/edit`, data)
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Update promotion success',
          });
          history.push(`${PATH.PROMOTION.LIST}/${id}/${code}`);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Form
        layout="vertical"
        name="updateUser"
        initialValues={{
          ...promotion,
          ...{
            expire: promotion ? moment(promotion.expire) : undefined,
          },
        }}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item
          label={t('promotion.create.form.expire')}
          name="expire"
          rules={[{ required: true, message: 'Xin hãy nhập expire!' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item label={t('promotion.create.form.package')} name="package">
          <Select>
            <Option value="standard_vnd">standard_vnd</Option>
            <Option value="standard_usd">standard_usd</Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('promotion.create.form.type')} name="type">
          <Select>
            <Option value="percent">Percent</Option>
            <Option value="percent_money">percent_money</Option>
            <Option value="money">money</Option>
            <Option value="month">month</Option>
            <Option value="percent_cond_month">percent_cond_month</Option>
            <Option value="money_cond_month">money_cond_month</Option>
            <Option value="month_cond_month">month_cond_month</Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('promotion.create.form.min')} name="min">
          <Input type={'number'} min={0} />
        </Form.Item>
        <Form.Item label={t('promotion.create.form.percent')} name="percent">
          <Input prefix="%" type={'number'} min={0} />
        </Form.Item>

        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label={t('promotion.create.form.money')} name="money">
              <PriceInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('promotion.create.form.unit')} name="unit">
              <Select>
                <Option value="VND">VND</Option>
                <Option value="USD">USD</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={t('promotion.create.form.month')} name="month">
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label={t('promotion.create.form.reward')}
          name="reward"
          rules={[{ required: true, message: 'Xin hãy nhập reward!' }]}
        >
          <PriceInput />
        </Form.Item>
        <Form.Item
          label={t('promotion.create.form.typeReward')}
          name="reward_type"
        >
          <Select>
            <Option value="percent">Percent</Option>
            <Option value="money">money</Option>
          </Select>
        </Form.Item>

        <Form.Item label={t('promotion.create.form.status')} name="status">
          <Select>
            <Option value="run">Run</Option>
            <Option value="stop">Stop</Option>
            <Option value="fail">Fail</Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('promotion.create.form.note')} name="note">
          <Input />
        </Form.Item>

        <Form.Item
          label={t('promotion.create.form.email')}
          name="email"
          rules={[
            { required: true, message: 'Xin hãy nhập Email!' },
            {
              type: 'email',
              message: 'Xin hãy nhập đúng định dạng Email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item className="staff-item" {...tailLayout}>
          <BackButton />
          <SaveButton htmlType="submit" />
        </Form.Item>
      </Form>
    ),
  };

  const getValueReward = (reward: string, count: string, unit = '') => {
    return `${formatter(reward)} x ${count} = ${formatter(
      +count * +reward
    )} ${unit}`;
  };
  const dataInfo = () => {
    const data = [
      { label: 'promotion.info.code', value: promotion.code },
      { label: 'Count', value: countPromotion.count },
    ];

    const sale =
      promotion.reward_type === 'percent'
        ? {
            label: 'Sales',
            value: formatter(countPromotion.sum) + ' ' + promotion.unit,
          }
        : null;
    if (sale) data.push(sale);
    const reward =
      promotion.reward_type === 'percent'
        ? { label: 'Reward', value: `${promotion.reward}%` }
        : {
            label: 'Reward',
            value: `${formatter(promotion.reward)} ${promotion.unit}`,
          };
    data.push(reward);
    const commission =
      promotion.reward_type === 'money'
        ? {
            label: 'Commission',
            value: getValueReward(
              promotion.reward,
              countPromotion.count,
              promotion.unit
            ),
          }
        : {
            label: 'Commission',
            value:
              formatter((promotion.reward * countPromotion.sum) / 100) +
              ' ' +
              (promotion.unit || ''),
          };
    data.push(commission);
    return data;
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {promotion && countPromotion && (
            <>
              <InfoWithAvatar
                id={id}
                data={dataInfo()}
                loading={loading}
                icon={countPromotion.count}
              />
              <LicenseStatus
                loading={loading}
                license={{
                  title: `${t('promotion.status.title')}: ${promotion.status}`,
                  begin: promotion.created_at,
                  end: promotion.expire,
                }}
              />
            </>
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditPromotion;
