import { Button, Form, Input, notification } from 'antd';
import { SECRET_PASSWORD } from 'config/contants';
import CryptoJS from 'crypto-js';
import { t } from 'i18n';
import { browserHistory } from 'pkg/route/helper';
import React, { useEffect, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { PATH } from 'routes/constants';

type Props = {
  isLoading: boolean;
  handleLogin: (values: any) => any;
};

const FormLogin = ({ isLoading, handleLogin }: Props) => {
  const usernameInput: any = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (usernameInput.current) {
      usernameInput.current.focus();
    }
  }, [usernameInput]);

  const checkGmail = (value: any) => {
    let isGmail = value?.username?.indexOf('@');
    let before = '';
    let after = '';
    let result = '';
    let spText = '';
    if (isGmail === -1) {
      result = value?.username?.split('.').join('');
    } else if (isGmail) {
      before = value?.username?.slice(0, isGmail);
      spText = before?.split('.').join('');

      after = value?.username?.slice(isGmail);

      result = `${spText}${after}`;
    }
    return result.toLowerCase();
  };

  const handleLoaded = async (value: any) => {
    value.username = checkGmail(value);
    if (!executeRecaptcha) {
      notification.error({
        message: t('Error'),
        description: 'Token in valid',
      });
      return;
    }
    const token = await executeRecaptcha('login');
    let encrypted = CryptoJS.AES.encrypt(value.password, SECRET_PASSWORD);
    let password: any = encrypted.toString();
    value.token = token;
    value.password = password;
    if (token) {
      handleLogin(value)
        .then((res: any) => {
          if (res) browserHistory.push(PATH.HOME);
        })
        .catch((err: any) => {
          notification.error({
            message: t('Error'),
            description: err.message,
          });
        });
    }
  };
  return (
    <Form
      onFinish={handleLoaded}
      name="basic"
      className="login-form__container"
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: t('authentication.error.please_input_username'),
          },
        ]}
      >
        <Input ref={usernameInput} placeholder={t('authentication.username')} />
      </Form.Item>

      <Form.Item
        className="mt-1"
        name="password"
        rules={[
          {
            required: true,
            message: t('authentication.error.please_input_password'),
          },
        ]}
      >
        <Input.Password placeholder={t('authentication.password')} />
      </Form.Item>
      <Form.Item>
        <Button
          className="mt-2"
          type="primary"
          // danger
          htmlType="submit"
          block={true}
          loading={isLoading}
          size={'large'}
          style={{
            fontWeight: 'bold',
            backgroundColor: '#d22736',
            fontSize: '30px',
          }}
        >
          {t('authentication.login')}
        </Button>
      </Form.Item>
      <div className="mt-2">
        <span>
          {t('authentication.noAccount')}{' '}
          <Link to={'/register'}>{t('authentication.registerNow')}</Link>
        </span>
      </div>
      <div className="">
        <Link to={'/get-password'}>{t('authentication.forgotPassword')}</Link>
      </div>
    </Form>
  );
};

export default FormLogin;
