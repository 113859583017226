import { useState } from 'react';
import { login } from './repo';

export function useLogin() {
  const [isLoading, setLoading] = useState(false);
  const handleLogin = async (value: any) => {
    if (value.username && value.password) {
      try {
        setLoading(true);
        return await login(value.username, value.password, value.token);
        // browserHistory.push(PATH.HOME);
      } finally {
        setLoading(false);
      }
    }
  };
  return {
    isLoading,
    handleLogin,
  };
}
