import { Button, Col, Row, Select, Tabs } from 'antd';
import { t } from 'i18n';
import { KEY_TIMEZONE } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ChartUrl from '../Tabs/ChartUrl';
import TabApp from '../Tabs/TabApp';
import TabUrl from '../Tabs/TabUrl';
import './AgentPage';
import './AgentPage.scss';
import ChartAgent from './ChartAgent';
import Keywords from '../Tabs/Keyword';

export default function AddAgent() {
  const [agentName, setAgentName] = useState<any>('');
  const [agents, setAgents] = useState<any>([]);

  const history = useHistory();
  let params: any = useParams();

  useEffect(() => {
    getList();
  }, [params.parent_id]);

  useEffect(() => {
    if (agents.length) {
      const user = agents.find((item: any) => item.value === params.agent_id);
      const data = user.label.split(' - ')[1];
      setAgentName(data);
    }
  }, [agents]);

  const { TabPane } = Tabs;

  const getAdminId = () => {
    return params.parent_id;
  };

  const getTimeZone = () => {
    return getCookie(KEY_TIMEZONE);
  };

  const getList = () => {
    promotionClient
      .post(`/api/v1/manager/agent_access/list`, {
        admin_id: params.parent_id,
      })
      .then((res: any) => {
        if (res.data.total) {
          const list = res.data.list;
          const options = list.map((item: any) => {
            return {
              value: item.agent_id,
              label: item.agent_id + ' - ' + item.user_id + ' - ' + item.name,
            };
          });

          setAgents(options);
        } else {
          setAgents([]);
        }
      });
  };

  const handleChange = (agent_id: string) => {
    history.push(
      `/agentpage/${agent_id}/${params.parent_id}/tabs/${params.tab}`
    );
  };

  return (
    <div className="mt-3">
      <Row gutter={16}>
        <Col flex="90px">
          <h3>Agent ID:</h3>
        </Col>
        <Col flex="auto" style={{ marginBottom: 10 }}>
          <Select
            defaultValue={params.agent_id}
            onChange={handleChange}
            options={agents}
            style={{ marginBottom: 10, marginRight: 10 }}
          />
          <Button
            type="primary"
            onClick={() =>
              history.push(`/children?parent_id=${params.parent_id}`)
            }
          >
            View settings
          </Button>
        </Col>
      </Row>

      <div className="card-container">
        <Tabs
          type="card"
          defaultActiveKey={params.tab}
          onChange={(key) => {
            history.push(
              `/agentpage/${params.agent_id}/${params.parent_id}/tabs/${key}`
            );
          }}
        >
          <TabPane style={{ padding: 5 }} tab={'URLs'} key={5}>
            {params.tab === '5' && (
              <TabUrl
                getAdminId={getAdminId}
                getTimeZone={getTimeZone}
                agentId={params.agent_id}
              />
            )}
          </TabPane>
          <TabPane
            style={{ padding: 5 }}
            tab={t('AgentPage.tabChartUrl')}
            key={4}
          >
            {params.tab === '4' && (
              <ChartUrl
                getAdminId={getAdminId}
                getTimeZone={getTimeZone}
                agentId={params.agent_id}
              />
            )}
          </TabPane>
          <TabPane
            style={{ padding: 5 }}
            tab={t('AgentPage.tabStatistics')}
            key={1}
          >
            {params.tab === '1' && (
              <TabApp
                getAdminId={getAdminId}
                getTimeZone={getTimeZone}
                agentId={params.agent_id}
              />
            )}
          </TabPane>
          <TabPane style={{ padding: 5 }} tab={t('AgentPage.tabChart')} key={2}>
            {params.tab === '2' && (
              <ChartAgent
                getAdminId={getAdminId}
                getTimeZone={getTimeZone}
                agentId={params.agent_id}
              />
            )}
          </TabPane>
          <TabPane style={{ padding: 5 }} tab={t('AgentPage.keywords')} key={6}>
            {params.tab === '6' && (
              <Keywords
                getAdminId={getAdminId}
                getTimeZone={getTimeZone}
                agentId={agentName}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
