import {
  ArrowDownOutlined,
  EditOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Card, notification, Popconfirm, Popover } from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Data {
  label: string;
  value: string;
}

interface Props {
  loading?: boolean;
  data: Data[];
  id?: string;
  icon?: string | React.ReactElement;
  path?: string;
  setLoading?: () => void;
  isUser?: boolean;
  user?: any;
}

const InfoWithAvatar = ({
  loading = false,
  data = [],
  id,
  path,
  icon = <UserOutlined />,
  setLoading,
  isUser = false,
  user = null,
}: Props) => {
  const history = useHistory();

  const confirm = () => {
    if (user) {
      if (setLoading) setLoading();
      promotionClient
        .post(`/api/v1/manager/subscription/downtofree`, {
          username: user.user_name,
        })
        .then((res: any) => {
          if (res.data.success) {
            notification.success({
              message: t('Success'),
              description: 'Update user success',
            });
          } else {
            notification.error({
              message: t('Error'),
              description: res.data.message,
            });
          }
          if (setLoading) setLoading();
        })
        .catch((err) => {
          if (setLoading) setLoading();
        });
    }
  };

  return (
    <Card
      loading={loading}
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      actions={
        id && path
          ? [
              isUser && (
                <Popover content="Down to free" trigger="hover">
                  <Popconfirm
                    title="Are you sure to down to free?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <ArrowDownOutlined key="setting" />
                  </Popconfirm>
                </Popover>
              ),
              <EditOutlined
                key="edit"
                onClick={() => history.push(`${path}`)}
              />,
            ]
          : [
              isUser && (
                <Popover content="Down to free" trigger="hover">
                  <Popconfirm
                    title="Are you sure to down to free?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <ArrowDownOutlined key="setting" />
                  </Popconfirm>
                </Popover>
              ),
            ]
      }
    >
      <Avatar size={100} icon={icon} style={{ marginBottom: '10px' }} />
      {data.length >= 1 &&
        data.map((item, index) => (
          <div key={index}>{`${t(item.label)} : ${item.value}`}</div>
        ))}
    </Card>
  );
};

export default InfoWithAvatar;
