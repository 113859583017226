import { Card, Col, Divider, notification, Row } from 'antd';
import { t } from 'i18n';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { PATH } from 'routes/constants';
const tabList = [
  {
    key: 'info',
    tab: t('rule.detail.title'),
  },
];

type Params = {
  id: string;
};

const DetailRule = () => {
  const router = useRouter<Params>();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const getDetailRule = (code: string) => {
    setLoading(true);
    promotionClient
      .post('/api/v1/manager/app_rule/blocklist/get', { id })
      .then((res: any) => {
        if (res.data) {
          setData(res.data.item);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getDetailRule(id);
    }
  }, [id]);

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Row gutter={[16, 16]}>
        <Col span={6} className="title-detail">
          {t('rule.list.type')}
        </Col>
        <Col span={18}>{data?.type}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('rule.list.name')}
        </Col>
        <Col span={18}>{data?.name}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('rule.list.rules')}
        </Col>
        <Col span={18}>{data?.list.toString()}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('rule.list.version')}
        </Col>
        <Col span={18}>{`${data?.version}`}</Col>
      </Row>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {data && (
            <>
              <InfoWithAvatar
                id={id}
                data={[]}
                loading={loading}
                path={`${PATH.RULE.LIST}/${id}/edit`}
              />
            </>
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailRule;
