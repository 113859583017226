import { IRoute } from 'pkg/route/types';
import { t } from 'i18n';
import { METHODS_ENUM } from '../../modules/methods_enum';

const ALL = 'all';

export const permission = (app: string, resource: string, action?: string) => {
  return [app, resource, action].filter((x) => !!x).join(':');
};

export const checkPermission = (
  permissions: Array<string>,
  app: string,
  resource: string,
  action?: string
) => {
  const perform = permission(app, resource, action);
  const performAllAction = permission(app, resource, ALL);
  const performAllResource = permission(app, ALL, ALL);
  const allPerforms = [perform, performAllAction, performAllResource];

  return !!(permissions && permissions.find((p) => allPerforms.includes(p)));
};

export const filterHasPermissions = (
  items: Array<IRoute>,
  currentUser: any
) => {
  return items.filter((item) => {
    // const { permissions, children } = item;
    if (!canDo(currentUser, METHODS_ENUM.userList) && item.path === '/users') {
      return false;
    }
    // if (!permissions) return true;
    // const intersection = permissions.find(x => {
    //   const { app, resource, action } = x;
    //   if (children) {
    //     if (!currentPermissions) return false;
    //     return currentPermissions.some(p => {
    //       return (
    //         p.startsWith(permission(app, resource, action)) ||
    //         p.startsWith(permission(app, resource, ALL)) ||
    //         p.startsWith(permission(app, ALL, ALL))
    //       );
    //     });
    //   }
    //   return checkPermission(currentPermissions, app, resource, action);
    // });
    // return !!intersection;
    return true;
  });
};

export const getRole = (value: boolean) => {
  if (value) {
    return t('role.admin');
  }
  return t('role.user');
};

// export const userNotFoundNotification = () => [
//   {
//     validator: (rule: any, value: any) => {
//       const pattern = /^[0-9]*$/;
//       if (
//         (value || value === 0) &&
//         (value < 1 || value > 10 || !pattern.test(value))
//       )
//         return Promise.reject(t('RequiredInputInRange', { min, max }));
//       return Promise.resolve();
//     },
//   },
// ];

export const canDo = (currentUser: any, action: string) => {
  return currentUser?.allowedMethods?.includes(action);
};
