import React, { useEffect, useState } from 'react';

import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  notification,
  Popconfirm,
  Row,
  Switch,
  Table,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

export default function AddGroupBlacklistForm(props: any) {
  const { Panel } = Collapse;

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div style={{ maxWidth: '500px' }} className={'mt-3'}>
      <Collapse
        style={{ border: '2px solid green' }}
        defaultActiveKey={'0'}
        accordion
        expandIcon={({ isActive }) => (
          <PlusCircleOutlined
            style={{ fontSize: '120%', color: 'green' }}
            rotate={isActive ? 130 : 0}
          />
        )}
      >
        <Panel header={`Create ${props.type}`} key="1">
          <Form
            form={form}
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={props.AddBlacklistGroup}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Name"
              name="name"
              className="staff-item"
              rules={[{ required: true, message: 'Please input name!' }]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              label={"List"}
              name="list"
              className='staff-item'
              rules={[{ required: true, message: 'Please input your Urls!' }]}
            >
              <Input />
            </Form.Item> */}

            <Form.Item className="staff-item" {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
}
