import { Button, Col, notification, Row, Select, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { t } from '../../../../i18n';
import { promotionClient } from '../../../../pkg/request/clients';
import './Information.scss';
type Props = {
  user: any;
  tab: string;
};

export default function Software({ user, tab }: Props) {
  const [agents, setAgents] = useState<any>([]);
  const [softwares, setSoftwares] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const agentId = useRef('');
  useEffect(() => {
    if (user && tab === '6') {
      getList();
    }
  }, [user, tab]);

  const getList = () => {
    promotionClient
      .post(`/api/v1/manager/agent_access/list`, {
        admin_id: user,
      })
      .then((res: any) => {
        if (res.data.total) {
          const options = res.data.list.map((item: any) => {
            return {
              value: item.agent_id,
              label: item.agent_id + ' - ' + item.user_id + ' - ' + item.name,
            };
          });
          agentId.current = res.data.list[0].agent_id;
          setAgents(options);
          getListSoftware(res.data.list[0].agent_id);
        } else {
          setAgents([]);
        }
      });
  };

  const getListSoftware = (agent_id: string) => {
    agentId.current = agent_id;
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/agent/list_software`, {
        agent_id,
      })
      .then((res: any) => {
        if (res.data.total) {
          setSoftwares(res.data.list);
          setLoading(false);
        } else {
          setSoftwares([]);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };
  const updateCmd = () => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/agent_command/send`, {
        agent_id: agentId.current,
        command: 'list_software',
        admin_id: user,
      })
      .then((res: any) => {
        setLoading(false);
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'List softwares successfully',
          });
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        setLoading(false);
        notification.error({
          message: t('Error'),
          description: err.message,
        });
      });
  };

  let columns = [
    {
      title: 'STT',
      render: (avatar: any, item: any, index: number) => index + 1,
    },
    {
      title: 'App name',
      dataIndex: 'display_name',
      key: 'display_name',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Publisher',
      dataIndex: 'publisher',
      key: 'publisher',
    },
    {
      title: 'Installed Date',
      dataIndex: 'installed_date',
      key: 'installed_date',
    },
  ];

  return (
    <div className="site-card-border-less-wrapper">
      {agents.length > 0 && (
        <>
          <Row>
            <Col flex="90px">
              <h3>Agent ID:</h3>
            </Col>
            <Col flex="auto" style={{ marginBottom: 10 }}>
              <Select
                value={agentId.current}
                defaultValue={agentId.current}
                style={{ marginBottom: 10, marginRight: 10 }}
                onChange={getListSoftware}
                options={agents}
              />
              <Button
                loading={loading}
                type="primary"
                onClick={() => updateCmd()}
              >
                List softwares
              </Button>
            </Col>
          </Row>
          <Table
            scroll={{ x: 600 }}
            dataSource={softwares}
            columns={columns}
            pagination={false}
            loading={loading}
            rowKey={(record: any) => record.display_name}
          />
        </>
      )}
    </div>
  );
}
