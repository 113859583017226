import { Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';

type Props = {
  options: any;
  initValue: any;
  onChange: (value: any) => void;
};

const { Option } = Select;

const ApplyRule = ({ options, initValue, onChange }: Props) => {
  return (
    <Select
      style={{ width: '100%' }}
      defaultValue={initValue}
      onChange={onChange}
    >
      <Option value="0">-- Select rule --</Option>
      {options &&
        options.map((item: any) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
    </Select>
  );
};

export default ApplyRule;
