import { Card, Col, Divider, notification, Row } from 'antd';
import { t } from 'i18n';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import LicenseStatus from 'modules/base/LicenseStatus';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { PATH } from 'routes/constants';
const tabList = [
  {
    key: 'info',
    tab: 'User detail',
  },
];

type Params = {
  id: string;
};

const DetailUser = () => {
  const router = useRouter<Params>();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);

  const getUser = (id: any) => {
    promotionClient
      .get(`/api/v1/manager/user/${id}`)
      .then((res: any) => {
        if (res.data) {
          setUser(res.data);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (id) getUser(id);
  }, [id]);

  const reloadUser = () => {
    if (id) getUser(id);
  };

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <div>
        <Row gutter={[16, 16]}>
          <Col span={6} className="title-detail">
            {t('users.detail.form.username')}
          </Col>
          <Col span={18}>{user?.user_name}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.email')}
          </Col>
          <Col span={18}>{user?.email}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.phone')}
          </Col>
          <Col span={18}>{user?.phone}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.fullName')}
          </Col>
          <Col span={18}>{user?.full_name}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.country')}
          </Col>
          <Col span={18}>{user?.country}</Col>
          <Divider className="diveder" />
          <Col span={6} className="title-detail">
            {t('users.detail.form.timezone')}
          </Col>
          <Col span={18}>{user?.time_zone}</Col>
        </Row>
      </div>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {user && (
            <InfoWithAvatar
              id={user.id}
              path={`${PATH.USER.PAGE}/${user.id}/edit`}
              data={[
                { label: 'users.detail.superRole', value: user.super_role },
                { label: 'users.detail.adminRole', value: user.admin_role },
              ]}
              loading={loading}
              isUser={true}
              setLoading={reloadUser}
              user={user}
            />
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
          {user && (
            <LicenseStatus
              loading={loading}
              license={{
                title: `${t('license.title')}: ${user.license}`,
                begin: user.subscription_begin,
                end: user.subscription_end,
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DetailUser;
