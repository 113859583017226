import { Avatar } from 'antd';
import React, { useEffect } from 'react';
import { REGIONS } from 'pkg/localization/constants';
import { changeLanguage } from 'pkg/localization/helpers';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Logo from '../../assets/images/android-chrome-128x128.png';
import FormLogin from './components/FormLogin';
import './Login.scss';
import { isLoggedIn } from 'pkg/user/repo';
import { browserHistory } from 'pkg/route/helper';
import { PATH } from 'routes/constants';
import { useLogin } from './hooks';

const Login: React.FC = () => {
  const { isLoading, handleLogin } = useLogin();

  const onUpdateLanguage = (key: any) => {
    changeLanguage(key);
  };
  useEffect(() => {
    if (isLoggedIn()) {
      browserHistory.push(PATH.HOME);
    }
  }, []);

  return (
    <div className="login-form">
      <div className="login-img">
        <a href="/">
          <img src={Logo} />
        </a>
      </div>
      <h1 className="login-form__title mb-3">SAFEWEB.APP</h1>
      <span className="app-user d-flex justify-content-center">
        <div
          className="mr-05 mb-1 cursor-poiter"
          onClick={() => onUpdateLanguage('en')}
        >
          <Avatar src={REGIONS.en.flag} shape="square" />
        </div>
        <div className="cursor-poiter" onClick={() => onUpdateLanguage('vi')}>
          <Avatar src={REGIONS.vi.flag} shape="square" />
        </div>
        {/* <span className="name">{currentRegion && currentRegion.name}</span> */}
      </span>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
        <FormLogin isLoading={isLoading} handleLogin={handleLogin} />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Login;
