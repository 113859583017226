import { Card, Col, Divider, notification, Row } from 'antd';
import { t } from 'i18n';
import InfoWithAvatar from 'modules/base/InfoWithAvatar';
import LicenseStatus from 'modules/base/LicenseStatus';
import { promotionClient } from 'pkg/request/clients';
import { useRouter } from 'pkg/route/hooks';
import React, { useEffect, useState } from 'react';
import { PATH } from 'routes/constants';
const tabList = [
  {
    key: 'info',
    tab: t('browser.detail.title'),
  },
];

type Params = {
  id: string;
};

const DetailBrowser = () => {
  const router = useRouter<Params>();
  const { id } = router.matchParams;
  const [loading, setLoading] = useState<boolean>(false);
  const [browsers, setBrowsers] = useState<any>(null);

  const getDetailBrowser = (code: string) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/black_browser/get`, { id })
      .then((res: any) => {
        if (res.data) {
          setBrowsers(res.data.item);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getDetailBrowser(id);
    }
  }, [id]);

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Row gutter={[16, 16]}>
        <Col span={6} className="title-detail">
          {t('browser.list.browserName')}
        </Col>
        <Col span={18}>{browsers?.browser_name}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('browser.list.fileName')}
        </Col>
        <Col span={18}>{browsers?.file_name}</Col>
        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('browser.list.originalName')}
        </Col>
        <Col span={18}>{`${browsers?.original_name}`}</Col>

        <Divider className="diveder" />
        <Col span={6} className="title-detail">
          {t('browser.list.fileDescription')}
        </Col>
        <Col span={18}>{browsers?.file_description}</Col>
      </Row>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={7}>
          {browsers && (
            <>
              <InfoWithAvatar
                id={id}
                data={[]}
                loading={loading}
                path={`${PATH.BROWSER.LIST}/${id}/edit`}
              />
            </>
          )}
        </Col>
        <Col span={17}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DetailBrowser;
