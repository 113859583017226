import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Row,
  Switch,
  Table,
  Tabs,
  Tag,
} from 'antd';
import { t } from 'i18n';
import { promotionClient } from 'pkg/request/clients';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import ChangeRoleUser from './components/ChangeRole';
import './User.scss';

const User = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    order: 'asc',
    page: 1,
    page_size: 10,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [showConfirmActiveUser, setShowConfirmActiveUser] = useState({
    show: false,
    id: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const { TabPane } = Tabs;

  useEffect(() => {
    getUsers(pagination);
  }, [pagination]);

  const handleChangeRole = (item: any) => {
    if (!item.id) return;
    setShowModal(true);
    setUser(item);
  };

  const handleSubmit = useCallback((user: any, data) => {
    setShowModal(!showModal);
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/subscription/uptostandard`, {
        username: user.user_name,
        count: data.total,
        type: 'monthly',
      })
      .then((res) => {
        if (res.data.success) {
          setShowModal(false);
          getUsers(pagination);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setShowModal(false);
        setLoading(false);
      });
  }, []);

  const handleActiveUser = (user: any) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/user/activate`, {
        username: user.user_name,
      })
      .then((res) => {
        if (res.data.success) {
          setShowConfirmActiveUser({ show: false, id: null });
          getUsers(pagination);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setShowConfirmActiveUser({ show: false, id: null });
        setLoading(false);
      });
  };

  const columns = [
    {
      title: t('users.list.no'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: t('users.list.username'),
      dataIndex: 'user_name',
      key: 'user_name',
      // with: '40%',
      render: (user_name: any, staff: any) => <span>{user_name}</span>,
      // sorter: (a: any, b: any) => a.full_name.length - b.full_name.length,
    },
    {
      title: t('users.list.email'),
      dataIndex: 'email',
      key: 'email',
      // with: '30%',
    },
    {
      title: t('users.list.phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('users.list.fullName'),
      dataIndex: 'full_name',
      key: 'full_name',
      // with: '30%',
      // sorter: (a: any, b: any) => a.full_name.length - b.full_name.length,
    },
    {
      title: t('users.list.country'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: t('users.list.role'),
      dataIndex: 'admin_role',
      key: 'admin_role',
      render: (admin_role: any, staff: any) => {
        const color = admin_role === 'standard' ? 'green' : 'warning';
        return (
          <Tag
            color={color}
            key={admin_role}
            onClick={() => handleChangeRole(staff)}
          >
            {admin_role}
          </Tag>
        );
      },
    },
    {
      title: t('users.list.member'),
      dataIndex: 'is_member',
      key: 'is_member',
      render: (is_member: any, user: any) => {
        return (
          <>
            <Popconfirm
              placement="topRight"
              title={'Change member status'}
              onConfirm={() => handleActiveUser(user)}
              onCancel={() => {
                setShowConfirmActiveUser({ show: false, id: null });
              }}
              okText="Yes"
              cancelText="No"
              visible={
                showConfirmActiveUser.show &&
                showConfirmActiveUser.id === user.id
              }
            >
              <Switch
                checkedChildren={'On'}
                unCheckedChildren={'Off'}
                checked={is_member}
                disabled={is_member}
                onClick={() =>
                  setShowConfirmActiveUser({ show: true, id: user.id })
                }
              />
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: t('users.list.action'),
      dataIndex: '',
      key: 'delete',
      render: (item: any, value: any) => (
        <div className="ant-btn-group" style={{ marginLeft: '5px' }}>
          <Button
            icon={<SearchOutlined />}
            onClick={() => history.push(`${PATH.USER.PAGE}/${value.id}`)}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => history.push(`${PATH.USER.PAGE}/${value.id}/edit`)}
          />
        </div>
      ),
    },
  ];

  const getUsers = (params: any) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/user/list`, params)
      .then((res: any) => {
        if (res.data.total) {
          setUsers(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (page: number, pageSize?: number) => {
    setPagination({
      ...pagination,
      page: page,
      page_size: pageSize || pagination.page_size,
    });
  };
  const handleCancel = useCallback(() => {
    setShowModal(!!showModal);
  }, []);

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="User manager" key="1">
          <Row gutter={15}>
            <Col className="gutter-row" lg={24} md={24} xs={24}>
              <div className="table-staff">
                <Table
                  rowKey={(record) => record.id}
                  bordered
                  pagination={false}
                  scroll={{ x: 600 }}
                  dataSource={users}
                  columns={columns}
                  loading={loading}
                />
              </div>
              <div className="user-pagination">
                <Pagination
                  defaultCurrent={pagination.page}
                  pageSize={pagination.page_size}
                  total={500}
                  onChange={handleTableChange}
                />
              </div>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      <Modal
        title={t('users.changeRole.title')}
        visible={showModal}
        footer={null}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <ChangeRoleUser
          handleSubmit={handleSubmit}
          staff={user}
          handleCancel={handleCancel}
        />
      </Modal>
    </div>
  );
};

export default User;
