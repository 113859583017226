import { Card, Col, Form, Input, notification, Row } from 'antd';
import { t } from 'i18n';
import { BackButton, SaveButton } from 'modules/base/Buttons';
import { promotionClient } from 'pkg/request/clients';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
const tabList = [
  {
    key: 'info',
    tab: 'Create a new block browser',
  },
];

const tailLayout = {
  wrapperCol: { span: 17 },
};

const CreateBrowser = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/black_browser/create`, data)
      .then((res: any) => {
        if (res.data) {
          notification.success({
            message: t('Success'),
            description: 'Create a new block browser successfully',
          });
          history.push(PATH.BROWSER.LIST);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const onFinishFailed = (val: any) => {};

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Row>
        <Col span={8}>
          <Form
            layout="vertical"
            name="updateUser"
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            <Form.Item
              label={t('browser.list.browserName')}
              name="browser_name"
              rules={[
                { required: true, message: 'Please enter browser name!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('browser.list.fileName')}
              name="file_name"
              rules={[
                { required: true, message: 'Please enter file browser name!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('browser.list.originalName')}
              name="original_name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('browser.list.fileDescription')}
              name="file_description"
            >
              <Input />
            </Form.Item>

            <Form.Item className="staff-item" {...tailLayout}>
              <BackButton />
              <SaveButton htmlType="submit" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateBrowser;
