import { Modal } from 'antd';
import React, { useRef, useState } from 'react';
import { SearchInput } from '../Children/components/SearchAndSelect';
import Setting from './components/History';

type Props = {};

const History = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const handleOk = () => {
    setUser(tempUser.current);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const tempUser = useRef('');
  const [user, setUser] = useState<string>('');
  const handleChange = (user: string) => {
    tempUser.current = user;
  };
  return (
    <div>
      <Modal
        title="History"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        Parent ID:{' '}
        <SearchInput
          placeholder="search parent"
          style={{ width: 200 }}
          handleChange={handleChange}
        />
      </Modal>
      {user ? <Setting user={user} /> : <p>Please select parent_id</p>}
    </div>
  );
};

export default History;
