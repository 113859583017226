import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
} from 'antd';
import { t } from 'i18n';
import { CancelButton, SaveButton } from 'modules/base/Buttons';
import PriceInput from 'modules/base/PriceInput';
import { promotionClient } from 'pkg/request/clients';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
const tabList = [
  {
    key: 'info',
    tab: 'Create a new promotion',
  },
];
const { Option } = Select;

const tailLayout = {
  wrapperCol: { span: 17 },
};

const CreatePromotion = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleLoaded = (val: any) => {
    const data = {
      ...val,
      expire: val.expire.format('YYYY-MM-DD'),
      money: val.money ? val.money.toString() : undefined,
    };
    setLoading(true);
    promotionClient
      .post(`/api/v1/manager/promotion/create`, data)
      .then((res: any) => {
        if (res.data.success) {
          history.push(PATH.PROMOTION.LIST);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const onFinishFailed = (val: any) => {};

  const contentList: Record<string, React.ReactNode> = {
    info: (
      <Row>
        <Col span={8}>
          <Form
            layout="vertical"
            name="updateUser"
            initialValues={{
              package: 'standard_vnd',
              unit: 'VND',
              type: 'percent',
              reward_type: 'money',
            }}
            onFinish={handleLoaded}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            <Form.Item
              label={t('promotion.create.form.code')}
              name="code"
              rules={[{ required: true, message: 'Xin hãy nhập số Code!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('promotion.create.form.expire')}
              name="expire"
              rules={[{ required: true, message: 'Xin hãy nhập expire!' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={t('promotion.create.form.package')}
              name="package"
            >
              <Select>
                <Option value="standard_vnd">standard_vnd</Option>
                <Option value="standard_usd">standard_usd</Option>
              </Select>
            </Form.Item>
            <Form.Item label={t('promotion.create.form.type')} name="type">
              <Select>
                <Option value="percent">Percent</Option>
                <Option value="percent_money">percent_money</Option>
                <Option value="money">money</Option>
                <Option value="month">month</Option>
                <Option value="percent_cond_month">percent_cond_month</Option>
                <Option value="money_cond_month">money_cond_month</Option>
                <Option value="month_cond_month">month_cond_month</Option>
              </Select>
            </Form.Item>
            <Form.Item label={t('promotion.create.form.min')} name="min">
              <Input type={'number'} min={0} />
            </Form.Item>
            <Form.Item
              label={t('promotion.create.form.percent')}
              name="percent"
            >
              <Input prefix="%" type={'number'} min={0} />
            </Form.Item>

            <Row gutter={[8, 8]}>
              <Col span={16}>
                <Form.Item
                  label={t('promotion.create.form.money')}
                  name="money"
                >
                  <PriceInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t('promotion.create.form.unit')} name="unit">
                  <Select>
                    <Option value="VND">VND</Option>
                    <Option value="USD">USD</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={t('promotion.create.form.month')} name="month">
              <InputNumber value={1} min={1} />
            </Form.Item>
            <Form.Item
              label={t('promotion.create.form.reward')}
              name="reward"
              rules={[{ required: true, message: 'Xin hãy nhập reward!' }]}
            >
              <PriceInput />
            </Form.Item>
            <Form.Item
              label={t('promotion.create.form.typeReward')}
              name="reward_type"
            >
              <Select>
                <Option value="percent">Percent</Option>
                <Option value="money">money</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={t('promotion.create.form.email')}
              name="email"
              rules={[
                { required: true, message: 'Xin hãy nhập Email!' },
                {
                  type: 'email',
                  message: 'Xin hãy nhập đúng định dạng Email!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item className="staff-item" {...tailLayout}>
              <CancelButton onClick={() => history.push(PATH.PROMOTION.LIST)} />
              <SaveButton htmlType="submit" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    ),
  };

  return (
    <div className="detail-user">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            loading={loading}
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={'info'}
          >
            {contentList['info']}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreatePromotion;
