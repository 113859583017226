import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, notification, Pagination, Row, Table, Tabs } from 'antd';
import { t } from 'i18n';
import { AddButton } from 'modules/base/Buttons';
import { SALE_MAN } from 'pkg/cookie/constants';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routes/constants';
import './Bank.scss';

const BankList = () => {
  const history = useHistory();
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    order: 'asc',
    page: 1,
    page_size: 10,
  });

  const { TabPane } = Tabs;
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (url) getBankList(pagination);
  }, [url]);

  const handleTableChange = (page: number, pageSize?: number) => {
    setPagination({
      ...pagination,
      page: page,
      page_size: pageSize || pagination.page_size,
    });
  };

  const columns = [
    {
      title: t('bank.list.no'),
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: t('bank.list.userName'),
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: t('bank.list.bankName'),
      dataIndex: 'bank_name',
      key: 'bank_name',
    },
    {
      title: t('bank.list.bankNumber'),
      dataIndex: 'bank_number',
      key: 'bank_number',
    },
    {
      title: t('bank.list.bankHolder'),
      dataIndex: 'bank_holder',
      key: 'bank_holder',
    },
    {
      title: t('bank.list.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: t('bank.list.updatedAt'),
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: t('bank.list.action'),
      dataIndex: '',
      key: 'delete',
      render: (item: any, value: any) => (
        <div className="ant-btn-group" style={{ marginLeft: '5px' }}>
          <Button
            icon={<SearchOutlined />}
            onClick={() => history.push(`${PATH.BANK.LIST}/${value.id}`)}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => history.push(`${PATH.BANK.LIST}/${value.id}/edit`)}
          />
        </div>
      ),
    },
  ];

  const operations = (
    <>
      <AddButton onClick={() => history.push(PATH.BANK.CREATE)}>
        Create
      </AddButton>
    </>
  );

  useEffect(() => {
    const role = getCookie('author_user');
    if (role) {
      if (role === SALE_MAN) {
        setUrl('/api/v1/manager/bank_info/list_mine');
      } else {
        setUrl('/api/v1/manager/bank_info/list');
      }
    }
  }, [getCookie('author_user')]);
  const getBankList = (params: any) => {
    setLoading(true);
    promotionClient
      .post(url, params)
      .then((res: any) => {
        if (res.data.total) {
          setBanks(res.data.list);
        } else {
          notification.error({
            message: t('Error'),
            description: res.data.message,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
      <TabPane tab={t('bank.list.title')} key="1">
        <Row gutter={15}>
          <Col className="gutter-row" lg={24} md={24} xs={24}>
            <div className="table-staff">
              <Table
                rowKey={(record) => record.id}
                bordered
                pagination={false}
                scroll={{ x: 600 }}
                dataSource={banks}
                columns={columns}
                loading={loading}
              />
            </div>
            <div className="user-pagination">
              <Pagination
                defaultCurrent={pagination.page}
                pageSize={pagination.page_size}
                total={500}
                onChange={handleTableChange}
              />
            </div>
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  );
};

export default BankList;
