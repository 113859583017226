import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import React from 'react';
import { PRICE_FORMAT, PRICE_MAX, PRICE_PARSER } from 'pkg/price/constants';

const formatter = (value: string | number | undefined): string => {
  return `${value}`.replace(PRICE_FORMAT, '.');
};

const parser = (value: string | undefined): number | string => {
  const intValue = parseInt(`${value}`.replace(PRICE_PARSER, ''));
  if (value && intValue > PRICE_MAX) {
    const maxValue = parseInt(
      `${value.slice(0, value?.length - 1)}`.replace(PRICE_PARSER, '')
    );
    return Number.isNaN(maxValue) ? '' : maxValue;
  }
  return Number.isNaN(intValue) ? '' : intValue;
};
interface PriceInputProps extends InputNumberProps {
  max?: number;
}

const PriceInput: React.FC<PriceInputProps> = React.forwardRef(
  (props: InputNumberProps, ref: React.Ref<any>) => {
    const { max } = props;
    return (
      <InputNumber
        min={0}
        ref={ref}
        max={max || PRICE_MAX}
        step={10 ** 4}
        formatter={formatter}
        parser={parser}
        {...props}
      />
    );
  }
);

export default PriceInput;
