import { createContext } from 'react';
export const currentUser = {
  sub: 'a160863da1644a119382cfc3e8685a7c',
  name: 'Đào Tô Tùng',
  picture:
    'https://lh3.googleusercontent.com/a-/AAuE7mDQFfGHKAJ9y6ZNwtoCyvvXfe6CY9b97C8ndmsg',
  email: 'tungdt@safeweb.app',
  phone_number: '0852161778',
  address: null,
  birthday: null,
  roles: ['catalog:admin'],
  permissions: ['catalog:all:all', 'ppm:all:all'],
  meta_data: {
    seller_id: '1,2',
    sso_id: 50563,
    addr_code: '01',
    department: 'COD',
    role: 'staff',
    title: 'Developer',
    asia_id: null,
    asia_username: null,
    manager_id: 50415,
    manager_asia_id: null,
    date_created: '2019-01-21 02:17:58',
    date_modified: '2019-01-21 02:19:26',
  },
};

export const StoreContext = createContext<any>({
  currentUser,
});

export const LoadingContext = createContext<any>({
  loading: false,
  setLoading: () => {},
});

export const ProductDetailContext = createContext<any>({
  currentFormChanged: false,
  setCurrentFormChanged: () => {},
});

export const ImageUploadContext = createContext<{
  disabled?: boolean;
  uploading?: boolean;
  setUploading?: Function;
}>({
  disabled: false,
  uploading: false,
  setUploading: () => {},
});

export const PendingContext = createContext<{
  pendings: string[];
  setPendings: (setter: (current: string[]) => string[]) => void;
}>({
  pendings: [],
  setPendings: () => {},
});

export const ScopePickerContext = createContext<{
  editable?: boolean;
  groupType?: string;
  defaultSetupTypes?: string[];
  showSetupTypes?: boolean;
}>({});
