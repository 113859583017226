import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useRef, useState } from 'react';
import KeywordData from './components/Keyword';
import { handleToDay } from './components/action';
type Props = {
  getAdminId: () => void;
  getTimeZone: () => void;
  agentId: string;
};
export default function Keywords({ getAdminId, getTimeZone, agentId }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = useState([]);
  const day = useRef(0);
  const currentPageNumber = useRef(1);

  useEffect(() => {
    getKeywords(agentId, day.current);
  }, [agentId]);

  const getKeywords = (
    agentId: string,
    daySelect: any = null,
    pageNumber?: any
  ) => {
    document.querySelector('div.ant-table-body')!.scrollTop = 0;
    setLoading(true);
    if (daySelect !== null) {
      currentPageNumber.current = 1;
      day.current = daySelect;
    }
    if (pageNumber) currentPageNumber.current = pageNumber;
    promotionClient
      .post(`/api/v1/manager/url_request/user_query`, {
        admin_id: getAdminId(),
        user_id: agentId,
        date: handleToDay(daySelect || day.current),
        top: 50,
        page: pageNumber || 1,
        timezone: getTimeZone(),
      })
      .then((res: any) => {
        if (res.data.success) {
          setData(res.data.search);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  console.log('data', data);
  return (
    <div className="mt-3">
      {/* {getAuthor() === 'standard' ? ( */}
      <div className="card-container">
        <KeywordData
          agentId={agentId}
          loading={loading}
          data={data}
          getKeywords={getKeywords}
          day={day.current}
          currentPageNumber={currentPageNumber.current || 1}
        />
        {/* <Tabs type="card" onChange={(key: any) => getKeywords(key)}>
          {staffList &&
            staffList.length > 0 &&
            staffList.map((staff: any) => {
              return (
                <TabPane
                  style={{ padding: 5 }}
                  tab={staff.full_name ?? staff.user_name}
                  key={staff.user_name}
                >
                  <KeywordData
                    agentId={staff.user_name}
                    loading={loading}
                    data={data}
                    getKeywords={getKeywords}
                    day={day.current}
                    currentPageNumber={currentPageNumber.current || 1}
                  />
                </TabPane>
              );
            })}
        </Tabs> */}
      </div>
      {/* ) : (
        <Row justify="center" align="middle" style={{ marginTop: '30px' }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>
              {t('whiteList.noti.package')}
            </h2>
            <Button
              type="primary"
              size="large"
              // onClick={() => history.push(PATH.BILLING.LIST)}
            >
              {t('billing.step1.btnUpgrade')}
              <DoubleRightOutlined />
            </Button>
          </Col>
        </Row>
      )} */}
    </div>
  );
}
